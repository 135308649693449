import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import moment from 'moment';
import {
    DirectionsBoat as DirectionsBoatIcon,
    DateRange as DateRangeIcon,
    Person as PersonIcon,
} from '@material-ui/icons';

const RCLStatus = ({ missionData }) => {
    let statLoc = '';
    let misTime = '';
    let crew = '';
    if (missionData) {
        statLoc = `RC Lillesand er for øyeblikket ute på ${missionData.missionCatName}`;
        misTime = `Oppdraget ble startet ${moment(missionData.startTime).format(
            'DD.MM-YYYY[, kl. ]HH:mm:ss'
        )}`;
        crew = `Båtfører på oppdraget er ${missionData.crew1Name}`;
        if (missionData.stopTime) {
            statLoc = `RC Lillesand er for øyeblikket ledig ved ${missionData.stopLoc}`;
            misTime = `Siste oppdrag ble avsluttet ${moment(
                missionData.stopTime
            ).format('DD.MM-YYYY[, kl. ]HH:mm:ss')}`;
            crew = `Båtfører siste oppdrag var ${missionData.crew1Name}`;
        }
    }
    return (
        <Card>
            <CardHeader title='Status RC Lillesand' />
            <CardContent>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <DirectionsBoatIcon />
                        </ListItemIcon>
                        <ListItemText primary={statLoc} />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <DateRangeIcon />
                        </ListItemIcon>
                        <ListItemText primary={misTime} />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary={crew} />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
};

export default RCLStatus;
