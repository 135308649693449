import React, { useState, useEffect, useContext } from 'react';
import ChangelogMenu from '../../components/changelog/ChangelogMenu';
import ChangeBacklog from '../../components/changelog/ChangeBacklog';
import ChangeDoing from '../../components/changelog/ChangeDoing';
import ChangeDone from '../../components/changelog/ChangeDone';
import Axios from 'axios';
import ChangeReqDialog from '../../components/changelog/ChangeReqDialog';
import { AuthContext } from '../../helpers/context/auth-context';
import StartDialog from '../../components/changelog/StartDialog';
import ChangeChangeReq from '../../components/changelog/ChangeChangeReq';

const Changelog = () => {
    const { userData } = useContext(AuthContext);
    const [selected, setSelected] = useState(null);
    const [menuValue, setMenuValue] = useState(0);
    const [newReqDialog, setNewReqDialog] = useState(false);
    const [startDialog, setStartDialog] = useState(false);
    const [progressDialog, setProgressDialog] = useState(false);
    const [changeList, setChangeList] = useState([]);
    const [changeReqApp, setChangeReqApp] = useState('');
    const [changeReqType, setChangeReqType] = useState('');
    const [changeReqDesc, setChangeReqDesc] = useState('');
    const handleNewReq = () => {
        if (
            changeReqApp !== '' &&
            changeReqType !== '' &&
            changeReqDesc !== '' &&
            userData
        ) {
            let payload = {
                author: userData.fullName,
                app: changeReqApp,
                type: changeReqType,
                description: changeReqDesc,
            };
            Axios.post('/v1/changelog', payload)
                .then((res) => {
                    setNewReqDialog(false);
                    fetchChangelogs();
                    setChangeReqType('');
                    setChangeReqApp('');
                    setChangeReqDesc('');
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            console.log('Missing data');
        }
    };
    const handleStart = () => {
        if (userData && selected) {
            if (userData.isAdmin) {
                let payload = {
                    started: Date.now(),
                };
                Axios.patch('/v1/changelog/' + selected.id, payload)
                    .then((res) => {
                        console.log(res);
                        setSelected(null);
                        fetchChangelogs();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    };
    const handleSelect = (index) => {
        if (userData) {
            if (userData.isAdmin) {
                const selectedItem = changeList.filter(
                    (change) => change.id === index
                );
                setSelected(selectedItem[0]);
            }
        }
    };
    const handleProgress = (progress, endNote) => {
        if (userData) {
            if (userData.isAdmin) {
                let payload = { progress: progress };
                if (endNote) {
                    payload.ended = Date.now();
                    payload.endnote = endNote;
                }
                console.log(payload);
                Axios.patch('/v1/changelog/' + selected.id, payload)
                    .then((res) => {
                        setSelected(null);
                        fetchChangelogs();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    };
    useEffect(() => {
        fetchChangelogs();
    }, []);
    const fetchChangelogs = () => {
        Axios.get('/v1/changelog')
            .then((res) => {
                setChangeList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    let subPage = (
        <ChangeBacklog
            changeList={changeList}
            setNewReqDialog={setNewReqDialog}
            handleSelect={handleSelect}
            setStartDialog={setStartDialog}
            setProgressDialog={setProgressDialog}
        />
    );
    if (menuValue === 1) {
        subPage = (
            <ChangeDoing
                changeList={changeList}
                setNewReqDialog={setNewReqDialog}
                handleSelect={handleSelect}
                setProgressDialog={setProgressDialog}
            />
        );
    }
    if (menuValue === 2) {
        subPage = (
            <ChangeDone
                changeList={changeList}
                setNewReqDialog={setNewReqDialog}
            />
        );
    }
    return (
        <React.Fragment>
            <StartDialog
                startDialog={startDialog}
                setStartDialog={setStartDialog}
                handleStart={handleStart}
            />
            <ChangeReqDialog
                newReqDialog={newReqDialog}
                setNewReqDialog={setNewReqDialog}
                changeReqApp={changeReqApp}
                setChangeReqApp={setChangeReqApp}
                changeReqType={changeReqType}
                setChangeReqType={setChangeReqType}
                changeReqDesc={changeReqDesc}
                setChangeReqDesc={setChangeReqDesc}
                handleNewReq={handleNewReq}
            />
            <ChangeChangeReq
                progressDialog={progressDialog}
                setProgressDialog={setProgressDialog}
                selected={selected}
                setSelected={setSelected}
                handleProgress={handleProgress}
            />
            <ChangelogMenu
                menuValue={menuValue}
                setMenuValue={setMenuValue}
                changeList={changeList}
                userData={userData}
            />
            {subPage}
        </React.Fragment>
    );
};

export default Changelog;
