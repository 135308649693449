import React from 'react';
import {
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const SingleIncActions = (props) => {
    let isOwner = false;
    const ended = props.selectedIncident.end ? true : false;
    if (!props.selectedIncident.handler_id) {
        isOwner = true;
    }
    if (props.selectedIncident.handler_id === props.userData.id) {
        isOwner = true;
    }
    return (
        <Accordion variant='outlined'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h5'>Handlinger for hendelse:</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            disabled={isOwner && !ended ? false : true}
                            size='large'
                            fullWidth
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                props.setPriDialog(true);
                            }}
                        >
                            Sett prioritet
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            disabled={isOwner && !ended ? false : true}
                            size='large'
                            fullWidth
                            color='primary'
                            variant='contained'
                            onClick={() => {
                                props.setResDialog(true);
                            }}
                        >
                            Sett ansvarlig
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Button
                            disabled={!props.selectedIncident.handler_id}
                            variant='contained'
                            color='primary'
                            fullWidth
                            size='large'
                            onClick={() => {
                                props.setLogEntry('');
                                props.setLogDialog(true);
                            }}
                        >
                            Ny loggføring
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            disabled={
                                isOwner &&
                                !ended &&
                                props.selectedIncident.handler_id
                                    ? false
                                    : true
                            }
                            variant='contained'
                            color='primary'
                            fullWidth
                            size='large'
                            onClick={() => {
                                props.setLogEntry('');
                                props.setEndDialog(true);
                            }}
                        >
                            Avslutt hendelse
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default SingleIncActions;
