import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    TablePagination,
} from '@material-ui/core';
import moment from 'moment';

const Backlog = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <Card style={{ margin: 10 }}>
            <CardHeader title='Hendelser: Inkommende' />
            <CardContent>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Opprettet</TableCell>
                                <TableCell>Kategori</TableCell>
                                <TableCell>Beskrivelse</TableCell>
                                <TableCell>Meldt av</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.incidentList
                                .filter(
                                    (incident) => incident.handler_id === null
                                )
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((incident) => {
                                    return (
                                        <TableRow
                                            key={incident.id}
                                            onClick={(evt) => {
                                                props.handleSelect(
                                                    evt,
                                                    incident.id
                                                );
                                            }}
                                            style={
                                                incident.priority === 3
                                                    ? { background: '#D98880' }
                                                    : incident.priority === 2
                                                    ? { background: '#F8C471' }
                                                    : incident.priority === 1
                                                    ? { background: '#82E0AA' }
                                                    : null
                                            }
                                        >
                                            <TableCell>{incident.id}</TableCell>
                                            <TableCell>
                                                {moment(incident.start).format(
                                                    'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {incident.catName}
                                            </TableCell>
                                            <TableCell>
                                                {incident.report.slice(0, 20) +
                                                    '...'}
                                            </TableCell>
                                            <TableCell>
                                                {incident.author}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component='div'
                    count={
                        props.incidentList.filter(
                            (incident) => incident.handler_id === null
                        ).length
                    }
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} av ${count}`
                    }
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage='Rader pr side'
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                />
            </CardContent>
        </Card>
    );
};

export default Backlog;
