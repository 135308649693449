import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    TextField,
    Button,
    Grid,
    Typography,
} from '@material-ui/core';
import {} from '@material-ui/icons';

const PersonalDataCard = ({
    username,
    setUsername,
    fullname,
    setFullname,
    email,
    setEmail,
    phone,
    setPhone,
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
    repeatPassword,
    setRepeatPassword,
    handleSaveUserDetails,
    handleSavePassword,
}) => {
    return (
        <React.Fragment>
            <Card style={{ margin: 10 }}>
                <CardHeader title='Informasjon' />
                <CardContent>
                    <Typography variant='body1'>
                        Informasjonen som blir lagret om deg blir kun brukt i
                        forbindelse med drift av RC-Lillesand. Mobilnummer er
                        frivillig å ha registrert, men man er avhengig av det
                        for å få til varslingsappen.
                        <br />
                        Ved spørsmål om hva som lagres kan administrator
                        kontaktes.
                    </Typography>
                </CardContent>
            </Card>
            <Card style={{ margin: 10 }}>
                <CardHeader title='Om meg:' />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id='username'
                                label='Brukernavn'
                                variant='outlined'
                                value={username}
                                onChange={(evt) => {
                                    setUsername(evt.target.value);
                                }}
                                type='text'
                                autoComplete='username'
                                required
                                fullWidth
                                style={{ marginBottom: 10 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id='fullname'
                                label='Fullt navn'
                                variant='outlined'
                                value={fullname}
                                onChange={(evt) => {
                                    setFullname(evt.target.value);
                                }}
                                type='text'
                                autoComplete='name'
                                required
                                fullWidth
                                style={{ marginBottom: 10 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id='email'
                                label='Email'
                                variant='outlined'
                                value={email}
                                onChange={(evt) => {
                                    setEmail(evt.target.value);
                                }}
                                type='email'
                                autoComplete='email'
                                required
                                fullWidth
                                style={{ marginBottom: 10 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id='phone'
                                label='Mobilnummer'
                                variant='outlined'
                                value={phone}
                                onChange={(evt) => {
                                    setPhone(evt.target.value);
                                }}
                                type='text'
                                autoComplete='mobile'
                                fullWidth
                                style={{ marginBottom: 10 }}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        color='primary'
                        variant='contained'
                        fullWidth
                        style={{ marginTop: 10 }}
                        onClick={handleSaveUserDetails}
                    >
                        Lage endringer
                    </Button>
                </CardContent>
            </Card>
            <Card style={{ margin: 10 }}>
                <CardHeader title='Nytt passord' />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id='current-password'
                                label='Nåværende passord'
                                variant='outlined'
                                autoComplete='current-password'
                                type='password'
                                value={currentPassword}
                                onChange={(evt) => {
                                    setCurrentPassword(evt.target.value);
                                }}
                                fullWidth
                                required
                                style={{ marginBottom: 10 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id='new-password'
                                label='Nytt Passord'
                                variant='outlined'
                                type='password'
                                value={newPassword}
                                onChange={(evt) => {
                                    setNewPassword(evt.target.value);
                                }}
                                required
                                autoComplete='new-password'
                                fullWidth
                                style={{ marginBottom: 10 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id='repeat-password'
                                label='Gjenta Nytt Passord'
                                variant='outlined'
                                type='password'
                                value={repeatPassword}
                                onChange={(evt) => {
                                    setRepeatPassword(evt.target.value);
                                }}
                                required
                                fullWidth
                                style={{ marginBottom: 10 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                color='primary'
                                variant='contained'
                                fullWidth
                                onClick={handleSavePassword}
                            >
                                Lagre nytt passord
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default PersonalDataCard;
