import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

const NoMatch = () => {
    return (
        <Alert severity='warning' style={{ margin: 10 }}>
            <AlertTitle>Ingen treff</AlertTitle>
            Siden du prøvde å gå inn på finnes ikke,
            <br /> eller så har du ikke riktig tilgang.
            <br /> Ta kontakt dersom du mener siden burde være der,
            <br /> eller om du skulle hatt tilgang...
        </Alert>
    );
};

export default NoMatch;
