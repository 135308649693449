import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AuthContextProvider from './helpers/context/auth-context';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/nb';
import * as serviceWorker from './serviceWorker';

moment.locale('nb');

ReactDOM.render(
    <React.StrictMode>
        <AuthContextProvider>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='nb'>
                <App />
            </MuiPickersUtilsProvider>
        </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
