import React, { useState, useContext } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Menu as MenuIcon } from '@material-ui/icons';
import { AuthContext } from '../../helpers/context/auth-context';
import LogOutDialog from '../LogOutDialog';

const Header = ({ toggleMenu }) => {
    const [logOutDialogOpen, setLogOutDialogOpen] = useState(false);
    const { token, setToken } = useContext(AuthContext);
    const { setUserData } = useContext(AuthContext);
    const handleDialogClose = () => {
        setLogOutDialogOpen(false);
    };
    const handleLogOut = () => {
        setLogOutDialogOpen(false);
        if (localStorage.getItem('token')) {
            localStorage.removeItem('token');
        }
        setToken();
        setUserData();
    };
    return (
        <React.Fragment>
            <LogOutDialog
                dialogOpen={logOutDialogOpen}
                handleDialogClose={handleDialogClose}
                handleLogOut={handleLogOut}
            />
            <AppBar position='static'>
                <Toolbar>
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={toggleMenu}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant='h6' style={{ flexGrow: 1 }}>
                        RC Lillesand WebPortal
                    </Typography>
                    {!token ? (
                        <Button
                            color='inherit'
                            component={RouterLink}
                            to='/innlogging'
                        >
                            Logg inn
                        </Button>
                    ) : (
                        <Button
                            color='inherit'
                            onClick={() => {
                                setLogOutDialogOpen(true);
                            }}
                        >
                            Logg ut
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

export default Header;
