import React from 'react';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableRow,
    TableCell,
    Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import moment from 'moment';

const ListTable = (props) => {
    moment.updateLocale('nb', {
        invalidDate: 'Ugyldig dato',
    });
    if (props.maintList.length > 0) {
        return (
            <Accordion
                style={{ margin: 10, background: props.background }}
                defaultExpanded={props.expanded}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant='h5'>
                        {props.title} (Antall: {props.maintList.length || 0})
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Tittel:</TableCell>
                                    <TableCell>Siste sjekk:</TableCell>
                                    <TableCell>Neste sjekk:</TableCell>
                                    <TableCell>Forfall:</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.maintList.map((maintitem) => {
                                    const maintStatus = moment().to(
                                        moment(maintitem.nextCheck || moment())
                                    );

                                    return (
                                        <TableRow
                                            key={maintitem.id}
                                            hover
                                            onClick={(evt) => {
                                                props.handleSelect(
                                                    maintitem.id
                                                );
                                            }}
                                        >
                                            <TableCell>
                                                {maintitem.title}
                                            </TableCell>
                                            <TableCell>
                                                {moment(
                                                    maintitem.lastCheck
                                                ).format('L')}
                                            </TableCell>
                                            <TableCell>
                                                {moment(
                                                    maintitem.nextCheck
                                                ).format('L')}
                                            </TableCell>
                                            <TableCell>{maintStatus}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        );
    } else {
        return <React.Fragment></React.Fragment>;
    }
};

export default ListTable;
