import React from 'react';
import {
    Dialog,
    DialogTitle,
    Grid,
    Button,
    DialogContent,
} from '@material-ui/core';

const StartDialog = (props) => {
    return (
        <Dialog
            open={props.startDialog}
            onClose={() => {
                props.setStartDialog(false);
            }}
            style={{ padding: 10 }}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle>
                Vil du markere aktuelle punkt som startet?
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={() => {
                                props.handleStart();
                                props.setStartDialog(false);
                            }}
                        >
                            Ja
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant='contained'
                            color='secondary'
                            fullWidth
                            onClick={() => {
                                props.setStartDialog(false);
                            }}
                        >
                            Nei
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default StartDialog;
