import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@material-ui/core';
import { Build as BuildIcon } from '@material-ui/icons';
const ImportantMessages = () => {
    return (
        <Card style={{ height: '100%' }}>
            <CardHeader title='Viktige meldinger:' />
            <CardContent>
                <List>
                    <ListItem button>
                        <ListItemIcon>
                            <BuildIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary='Under konstruksjon'
                            secondary='Vær OBS på at det er flere ting i portalen som ikke er implementert ennå. Pr nå er følgende mulig å bruke. [Hjem, Min Profil, Mine Oppdrag]'
                        />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
};

export default ImportantMessages;
