import React, { useContext, useState, useEffect } from 'react';
import RCLStatus from '../../components/RCLStatusCard';
import { Grid } from '@material-ui/core';
import { AuthContext } from '../../helpers/context/auth-context';
import WelcomeComp from '../../components/WelcomeComp';
import ImportantMessages from '../../components/ImportantMessages';
import Axios from 'axios';
const Home = () => {
    const { token } = useContext(AuthContext);
    const [missionData, setMissionData] = useState();
    //const [messages, setMessages] = useState([]);

    useEffect(() => {
        if (token) {
            console.log('Fetching last mission');
            Axios.get('v1/lastmission')
                .then((res) => {
                    setMissionData(res.data[0]);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [token]);
    return (
        <Grid container>
            {token ? (
                <Grid item xs={12} style={{ padding: 10 }}>
                    <RCLStatus missionData={missionData} />
                </Grid>
            ) : null}
            <Grid item xs={12} sm={token ? 6 : 12} style={{ padding: 10 }}>
                <WelcomeComp />
            </Grid>
            {token ? (
                <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                    <ImportantMessages />
                </Grid>
            ) : null}
        </Grid>
    );
};

export default Home;
