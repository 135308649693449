import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
export const AuthContext = createContext();

const AuthContextProvider = (props) => {
    const prevToken = window.localStorage.getItem('token') || null;
    const [token, setToken] = useState(prevToken);
    const [userData, setUserData] = useState();
    const [mySarBacklog, setMySarBacklog] = useState(0);
    //axios.defaults.baseURL = 'http://192.168.39.162:8080/';
    axios.defaults.baseURL = 'https://api.lillesandrk.no/';
    //axios.defaults.baseURL = 'http://192.168.0.105:8080/';
    axios.defaults.headers.common['x-access-auth'] = token;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error.message === 'Network Error') {
                return Promise.reject(error);
            }
            switch (error.response.status) {
                case 400:
                    break;
                case 401:
                    break;
                case 403:
                    if (error.response.data.msg === 'jwt expired') {
                        if (localStorage.getItem('token')) {
                            localStorage.removeItem('token');
                        }
                        setToken();
                    }
                    break;
                case 404:
                    // Show 404 page
                    break;
                case 500:
                    // Serveur Error redirect to 500
                    break;
                default:
                    break;
            }
            return Promise.reject(error);
        }
    );
    const handleRefreshUserData = () => {
        axios
            .get('v1/user/me')
            .then((res) => {
                setUserData(res.data[0]);
            })
            .catch((err) => {});
    };
    useEffect(() => {
        if (token) {
            axios
                .get('v1/user/me')
                .then((res) => {
                    setUserData(res.data[0]);
                })
                .catch((err) => {});
        }
    }, [token]);

    const defaultContext = {
        token,
        setToken,
        userData,
        setUserData,
        handleRefreshUserData,
        mySarBacklog,
        setMySarBacklog,
    };

    return (
        <AuthContext.Provider value={defaultContext}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
