import React from 'react';
import {
    Dialog,
    DialogTitle,
    Grid,
    Button,
    DialogContent,
} from '@material-ui/core';

const LogOutDialog = ({ dialogOpen, handleDialogClose, handleLogOut }) => {
    return (
        <Dialog
            onClose={handleDialogClose}
            open={dialogOpen}
            style={{ padding: 10 }}
        >
            <DialogTitle>Vil du virkelig logge ut?</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={handleLogOut}
                        >
                            Ja
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant='contained'
                            color='secondary'
                            fullWidth
                            onClick={handleDialogClose}
                        >
                            Nei
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default LogOutDialog;
