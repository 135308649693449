import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    TablePagination,
    Typography,
    Divider,
} from '@material-ui/core';
import moment from 'moment';

const DashBoard = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    if (props.userData) {
        return (
            <Card style={{ margin: 10 }}>
                <CardHeader title='Hendelser: Dahsboard' />
                <CardContent>
                    <Typography variant='h6'>
                        Hei, {props.userData.fullName}
                    </Typography>
                    <Typography variant='body1' style={{ marginBottom: 10 }}>
                        Du er nå i admin modulen til hendelsesrapporter.
                    </Typography>
                    <Typography variant='body1' style={{ marginBottom: 10 }}>
                        <strong>Dashboard:</strong>
                        Undersiden dashboard inneholder informasjon om modulen,
                        samt en liste med de aktive hendelsene som du er satt
                        som ansvarlig på.
                    </Typography>
                    <Typography variant='body1' style={{ marginBottom: 10 }}>
                        <strong>Innkommende:</strong>
                        Innkommende er alle nye hendelser som ikke har fått noen
                        som er ansvarlig for oppfølging ennå. Så fort ansvar
                        blir gitt til en person vil den gå over i listen over
                        "pågående" hendelser.
                    </Typography>
                    <Typography variant='body1' style={{ marginBottom: 10 }}>
                        <strong>Pågående:</strong>
                        Undersiden med pågående hendelser er alle hendelser som
                        har fått en som er ansvarlig for oppfølging. Kun den som
                        er ansvarlig vil kunne sette ny ansvarlig person, endre
                        prioritet eller lukke hendelsen. Alle som har tilgang
                        til modulen(primært driftsgruppa) vil kunne legge til
                        merknader i loggen.
                    </Typography>
                    <Typography variant='body1'>
                        <strong>Avsluttet:</strong>
                        Avsluttet er alle hendelser som er merket som avsluttet
                        av den som er ansvarlig for oppfølging av hendelsen.
                        Disse kan ikke åpnes igjen, men det går fortsatt an å
                        registrere ting i loggen.
                    </Typography>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Typography variant='h5'>Mine hendelser:</Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Opprettet</TableCell>
                                    <TableCell>Kategori</TableCell>
                                    <TableCell>Beskrivelse</TableCell>
                                    <TableCell>Meldt av</TableCell>
                                    <TableCell>Håndteres av</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.incidentList
                                    .filter(
                                        (incident) =>
                                            incident.handler_id ===
                                                props.userData.id &&
                                            incident.end === null
                                    )
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((incident) => {
                                        return (
                                            <TableRow
                                                key={incident.id}
                                                onClick={(evt) => {
                                                    props.handleSelect(
                                                        evt,
                                                        incident.id
                                                    );
                                                }}
                                                style={
                                                    incident.priority === 3
                                                        ? {
                                                              background:
                                                                  '#D98880',
                                                          }
                                                        : incident.priority ===
                                                          2
                                                        ? {
                                                              background:
                                                                  '#F8C471',
                                                          }
                                                        : incident.priority ===
                                                          1
                                                        ? {
                                                              background:
                                                                  '#82E0AA',
                                                          }
                                                        : null
                                                }
                                            >
                                                <TableCell>
                                                    {incident.id}
                                                </TableCell>
                                                <TableCell>
                                                    {moment(
                                                        incident.start
                                                    ).format(
                                                        'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {incident.catName}
                                                </TableCell>
                                                <TableCell>
                                                    {incident.report.slice(
                                                        0,
                                                        20
                                                    ) + '...'}
                                                </TableCell>
                                                <TableCell>
                                                    {incident.author}
                                                </TableCell>
                                                <TableCell>
                                                    {incident.fullName}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component='div'
                        count={
                            props.incidentList.filter(
                                (incident) =>
                                    incident.handler_id === props.userData.id &&
                                    incident.end === null
                            ).length
                        }
                        labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} av ${count}`
                        }
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage='Rader pr side'
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                    />
                </CardContent>
            </Card>
        );
    } else {
        return null;
    }
};
export default DashBoard;
