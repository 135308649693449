import React from 'react';
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import moment from 'moment';

const SingleIncLogs = (props) => {
    return (
        <Accordion variant='outlined' defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h5'>Hendelseslogg:</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tid:</TableCell>
                                <TableCell>Hvem:</TableCell>
                                <TableCell>Merknad:</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.incidentLog
                                ? props.incidentLog.map((logEntry) => (
                                      <TableRow key={logEntry.id}>
                                          <TableCell>
                                              {moment(
                                                  logEntry.timestamp
                                              ).format(
                                                  'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                              )}
                                          </TableCell>
                                          <TableCell>
                                              {logEntry.fullName}
                                          </TableCell>
                                          <TableCell>
                                              <Typography
                                                  component='pre'
                                                  variant='body2'
                                              >
                                                  {logEntry.remark}
                                              </Typography>
                                          </TableCell>
                                      </TableRow>
                                  ))
                                : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    );
};

export default SingleIncLogs;
