import React, { useState, useEffect, useCallback, useContext } from 'react';
import Axios from 'axios';
import MissionTable from '../../components/MissionTable';
import SingleMission from '../../components/SingleMission';
import ErrorAlert from '../../components/common/ErrorAlert';
import { AuthContext } from '../../helpers/context/auth-context';
import moment from 'moment';

const MyMissions = () => {
    const { userData } = useContext(AuthContext);
    const [error, setError] = useState();
    const [missionList, setMissionList] = useState([]);
    const [missionLog, setMissionLog] = useState([]);
    const [singleMission, setSingleMission] = useState();
    const [startLoc, setStartLoc] = useState('');
    const [stopLoc, setStopLoc] = useState('');
    const [startEng, setStartEng] = useState('');
    const [stopEng, setStopEng] = useState('');
    const [distance, setDistance] = useState('');
    const [startTime, setStartTime] = useState();
    const [stopTime, setStopTime] = useState();
    const [missionCat, setMissionCat] = useState([]);
    const [category, setCategory] = useState();
    const [logDialogOpen, setLogDialogOpen] = useState(false);
    const [logEntryInput, setLogEntryInput] = useState('');

    const fetchLogEntries = useCallback(() => {
        Axios.get(`v1/missionlog/${singleMission.id}`)
            .then((res) => {
                setMissionLog(res.data);
            })
            .catch((err) => {
                setMissionLog([]);
                console.log(err);
            });
    }, [singleMission]);
    const fetchMyMissions = useCallback(() => {
        Axios.get('v1/mymission')
            .then((res) => {
                setMissionList(res.data);
            })
            .catch((err) => {
                setError({
                    type: 'error',
                    title: 'Feil under lasting av data',
                    msg: ['Noe gikk galt under lasting av data fra API'],
                });
            });
    }, []);
    useEffect(() => {
        fetchMyMissions();
    }, [fetchMyMissions]);
    useEffect(() => {
        Axios.get('v1/missioncat')
            .then((res) => {
                setMissionCat(res.data);
            })
            .catch((err) => {
                setError({
                    type: 'error',
                    title: 'Feil under lasting av data',
                    msg: ['Noe gikk galt under lasting av data fra API'],
                });
            });
    }, []);
    useEffect(() => {
        if (singleMission) {
            fetchLogEntries();
        }
    }, [singleMission, fetchLogEntries]);
    const handleSelect = (evt, index) => {
        const selectedMission = missionList.filter((mission) => {
            return mission.id === index;
        });
        setSingleMission(selectedMission[0]);
        setStartLoc(selectedMission[0].startLoc);
        setStopLoc(selectedMission[0].stopLoc);
        setStartEng(selectedMission[0].startEngine);
        setStopEng(selectedMission[0].stopEngine);
        setDistance(selectedMission[0].distance);
        setCategory(
            missionCat[
                missionCat
                    .map((cat) => {
                        return cat.name;
                    })
                    .indexOf(selectedMission[0].missionCatName)
            ]
        );
        setStartTime(new Date(selectedMission[0].startTime));
        setStopTime(new Date(selectedMission[0].stopTime));
    };
    const refreshCurrentMission = () => {
        Axios.get('v1/mymission/' + singleMission.id)
            .then((res) => {
                setSingleMission(res.data[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleUpdateMission = () => {
        let validationError = [];
        let changeDetected = false;
        let changeLogQuery = [];
        setError();
        if (singleMission.startLoc !== startLoc) {
            if (startLoc === '') {
                validationError.push('Startlokasjon kan ikke være tom...');
                setStartLoc(singleMission.startLoc);
            }
            changeLogQuery.push(
                `Startlokasjon endret fra ${singleMission.startLoc} til ${startLoc}`
            );
            changeDetected = true;
        }
        if (singleMission.stopLoc !== stopLoc) {
            if (stopLoc === '') {
                validationError.push('Stopplokasjon kan ikke være tom...');
                setStopLoc(singleMission.stopLoc);
            }
            changeLogQuery.push(
                `Stopplokasjon endret fra ${singleMission.stopLoc} til ${stopLoc}`
            );
            changeDetected = true;
        }
        if (singleMission.startEngine !== parseFloat(startEng)) {
            if (parseFloat(startEng) > parseFloat(stopEng)) {
                validationError.push(
                    'Motortimer start kan ikke være større enn motortimer slutt...'
                );
                setStartEng(singleMission.startEngine);
            }
            if (startEng === '' || parseFloat(startEng) < 0) {
                validationError.push(
                    'Motortimer kan ikke være tom eller negativ...'
                );
                setStartEng(singleMission.startEngine);
            }
            if (isNaN(parseFloat(startEng))) {
                validationError.push('Motortimer ved start må være et tall...');
                setStopEng(singleMission.startEngine);
            }
            changeLogQuery.push(
                `Motortimer ved start er endret fra ${singleMission.startEngine} til ${startEng}`
            );
            changeDetected = true;
        }
        if (singleMission.stopEngine !== parseFloat(stopEng)) {
            if (parseFloat(startEng) > parseFloat(stopEng)) {
                validationError.push(
                    'Motortimer start kan ikke være større enn motortimer slutt...'
                );
                setStopEng(singleMission.stopEngine);
            }
            if (stopEng === '' || parseFloat(stopEng < 0)) {
                validationError.push(
                    'Motortimer kan ikke være tom eller negativ...'
                );
                setStopEng(singleMission.stopEngine);
            }
            if (isNaN(parseFloat(stopEng))) {
                validationError.push('Motortimer ved slutt må være et tall...');
                setStopEng(singleMission.stopEngine);
            }
            changeLogQuery.push(
                `Motortimer ved slutt er endret fra ${singleMission.stopEngine} til ${stopEng}`
            );
            changeDetected = true;
        }
        if (singleMission.distance !== parseFloat(distance)) {
            if (isNaN(parseFloat(distance))) {
                validationError.push('Avstand må være et tall...');
                setDistance(singleMission.distance);
            }
            if (parseFloat(distance) < 0 || distance === '') {
                validationError.push(
                    'Avstand kan ikke være negativ eller tom...'
                );
                setDistance(singleMission.distance);
            }
            changeDetected = true;
            changeLogQuery.push(
                `Avstand er endret fra ${singleMission.distance} nm, til ${distance} nm`
            );
        }
        if (category) {
            if (singleMission.missionCatName !== category.name) {
                changeDetected = true;
                changeLogQuery.push(
                    `Oppdragskategori er endret fra ${singleMission.missionCatName} til ${category.name}`
                );
            }
        }
        if (
            startTime.getTime() !== new Date(singleMission.startTime).getTime()
        ) {
            if (startTime.getTime() > stopTime.getTime()) {
                validationError.push(
                    'Starttid kan ikke være større enn stopptid...'
                );
                setStartTime(new Date(singleMission.startTime));
            }
            changeLogQuery.push(
                `Starttid er endret fra ${moment(
                    singleMission.startTime
                ).format('DD.MM-YYYY[, kl. ]HH:mm:ss')} til ${moment(
                    startTime
                ).format('DD.MM-YYYY[, kl. ]HH:mm:ss')}`
            );
            changeDetected = true;
        }
        if (stopTime.getTime() !== new Date(singleMission.stopTime).getTime()) {
            if (startTime.getTime() > stopTime.getTime()) {
                validationError.push(
                    'Stopptid kan ikke være mindre enn starttid...'
                );
                setStopTime(new Date(singleMission.stopTime));
            }
            changeLogQuery.push(
                `Stopptid er endret fra ${moment(singleMission.stopTime).format(
                    'DD.MM-YYYY[, kl. ]HH:mm:ss'
                )} til ${moment(stopTime).format('DD.MM-YYYY[, kl. ]HH:mm:ss')}`
            );
            changeDetected = true;
        }
        if (validationError.length > 0) {
            setError({
                type: 'error',
                title: 'Validering feilet',
                msg: validationError,
            });
        }
        if (changeDetected && validationError.length === 0) {
            let payload = {
                starttime: startTime.getTime(),
                startengine: parseFloat(startEng),
                startloc: startLoc,
                stoptime: stopTime.getTime(),
                stopengine: parseFloat(stopEng),
                stoploc: stopLoc,
                distance: parseFloat(distance),
            };
            if (category) {
                payload.missioncatid = category.id;
                payload.missioncatname = category.name;
            }
            Axios.patch(`v1/mission/${singleMission.id}`, payload)
                .then((res) => {
                    for (let i in changeLogQuery) {
                        changeLogQuery[i] =
                            changeLogQuery[i] +
                            ' : Endret av:' +
                            userData.fullName;
                        saveNewLogEntry(
                            singleMission.id,
                            'SYSTEM',
                            changeLogQuery[i]
                        );
                    }
                    refreshCurrentMission();
                    setError({
                        type: 'success',
                        title: 'Oppdraget oppdatert',
                        msg: [
                            'Oppdragsdata for gjeldende oppdrag er lagret til database...',
                        ],
                    });
                })
                .catch((err) => {});
        }
    };
    const handleAddLog = () => {
        if (logEntryInput === '') {
            setError({
                type: 'error',
                title: 'Noe gikk galt',
                msg: [
                    'Om du skal lagre en ny loggføring kan den ikke være blank...',
                ],
            });
        } else {
            saveNewLogEntry(
                singleMission.id,
                'ADM',
                `${userData.fullName} skrev: ${logEntryInput}`
            );
            setLogEntryInput('');
            setLogDialogOpen(false);
        }
    };
    const handleCloseSingleMission = () => {
        setSingleMission();
        fetchMyMissions();
    };
    const saveNewLogEntry = (missionid, logtype, logentry) => {
        if (missionid && logtype && logentry) {
            const payload = {
                missionid: missionid,
                category: logtype,
                remark: logentry,
                timestamp: Date.now(),
            };
            Axios.post('v1/missionlog', payload)
                .then((res) => {
                    fetchLogEntries();
                })
                .catch((err) => {
                    setError({
                        type: 'error',
                        title: 'Noe gikk galt',
                        msg: ['Noe gikk galt under lagring av loggdata...'],
                    });
                });
        }
    };
    const handleNewSar = () => {
        if (singleMission) {
            Axios.post(`v1/sar/${singleMission.id}`)
                .then((res) => {
                    console.log('Lagret');
                    refreshCurrentMission();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const handleNewInvoice = () => {
        if (singleMission) {
            Axios.post(`v1/invoice/${singleMission.id}`)
                .then((res) => {
                    console.log('Lagret');
                    refreshCurrentMission();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <React.Fragment>
            {error ? <ErrorAlert errorMsg={error} setError={setError} /> : null}
            {!singleMission ? (
                <MissionTable
                    missionList={missionList}
                    handleSelect={handleSelect}
                    tableTitle='Mine oppdrag'
                />
            ) : (
                <SingleMission
                    userData={userData}
                    mission={singleMission}
                    handleCloseSingleMission={handleCloseSingleMission}
                    missionLog={missionLog}
                    startLoc={startLoc}
                    setStartLoc={setStartLoc}
                    stopLoc={stopLoc}
                    setStopLoc={setStopLoc}
                    startEng={startEng}
                    setStartEng={setStartEng}
                    stopEng={stopEng}
                    setStopEng={setStopEng}
                    distance={distance}
                    setDistance={setDistance}
                    startTime={startTime}
                    setStartTime={setStartTime}
                    stopTime={stopTime}
                    setStopTime={setStopTime}
                    missionCat={missionCat}
                    category={category}
                    setCategory={setCategory}
                    handleUpdateMission={handleUpdateMission}
                    logDialogOpen={logDialogOpen}
                    setLogDialogOpen={setLogDialogOpen}
                    logEntryInput={logEntryInput}
                    setLogEntryInput={setLogEntryInput}
                    handleAddLog={handleAddLog}
                    handleNewSar={handleNewSar}
                    handleNewInvoice={handleNewInvoice}
                />
            )}
        </React.Fragment>
    );
};

export default MyMissions;
