import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    CardMedia,
    Typography,
    Grid,
} from '@material-ui/core';
import rclBeredskap from '../assets/rclBeredskap.jpg';

const WelcomeComp = () => {
    return (
        <Card style={{ height: '100%' }}>
            <CardHeader title='Velkommen til RC Lillesand' />
            <CardContent>
                <Grid container>
                    <Grid item xs={7}>
                        <Typography>
                            Portalen er ment som et hjelpemiddel for
                            administrasjon av daglig drift på båten og vil
                            utvikles kontinuerlig ettersom behovene dukker opp.
                            <br />
                            Vær OBS på at du må være innlogget for å ha noe
                            nytte av funksjonene i portalen.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <CardMedia
                            image={rclBeredskap}
                            alt='RC Lillesand'
                            style={{ height: '100%', minHeight: 300 }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default WelcomeComp;
