import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Grid,
    Button,
} from '@material-ui/core';

const NewLogEntryDialog = ({
    logDialogOpen,
    setLogDialogOpen,
    logEntryInput,
    setLogEntryInput,
    handleAddLog,
}) => {
    return (
        <Dialog
            open={logDialogOpen}
            onClose={() => {
                setLogDialogOpen(false);
            }}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>Ny loggføring</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant='outlined'
                            fullWidth
                            value={logEntryInput}
                            onChange={(evt) => {
                                setLogEntryInput(evt.target.value);
                            }}
                            label='Loggføring'
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={handleAddLog}
                        >
                            Lagre
                        </Button>{' '}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant='contained'
                            color='secondary'
                            fullWidth
                            onClick={() => {
                                setLogDialogOpen(false);
                            }}
                        >
                            Avbryt
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default NewLogEntryDialog;
