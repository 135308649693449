import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Chip,
    CircularProgress,
    Box,
    Typography,
    Icon,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import moment from 'moment';

const ChangeDone = (props) => {
    return (
        <Card style={{ margin: 10 }}>
            <CardHeader
                title='Innkommende:'
                action={
                    <Tooltip title='Ny henvendelse'>
                        <IconButton
                            aria-label='settings'
                            onClick={() => {
                                props.setNewReqDialog(true);
                            }}
                        >
                            <AddCircleIcon color='primary' fontSize='large' />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: 100 }}>Ferdig</TableCell>
                            <TableCell style={{ width: 100 }}>Hvor</TableCell>
                            <TableCell style={{ width: 150 }}>Type</TableCell>
                            <TableCell style={{ width: 100 }}>
                                Progresjon
                            </TableCell>
                            <TableCell>Konklusjon</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.changeList
                            .filter((change) => change.ended !== null)
                            .map((change) => {
                                let moduleChip = <Chip />;
                                switch (change.app) {
                                    case 1:
                                        moduleChip = (
                                            <Chip
                                                label='Portal'
                                                icon={
                                                    <Icon>library_books</Icon>
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        );
                                        break;
                                    default:
                                        moduleChip = (
                                            <Chip
                                                label='Båt-APP'
                                                icon={
                                                    <Icon>directions_boat</Icon>
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        );
                                        break;
                                }
                                let typeChip = <Chip />;
                                switch (change.type) {
                                    case 2:
                                        typeChip = (
                                            <Chip
                                                label='Nytt'
                                                icon={
                                                    <Icon>add_to_photos</Icon>
                                                }
                                                style={{
                                                    width: '100%',
                                                    background: '#66ffcc',
                                                }}
                                            />
                                        );
                                        break;
                                    case 3:
                                        typeChip = (
                                            <Chip
                                                label='Endring'
                                                icon={<Icon>border_color</Icon>}
                                                style={{
                                                    width: '100%',
                                                    background: '#99ccff',
                                                }}
                                            />
                                        );
                                        break;
                                    default:
                                        typeChip = (
                                            <Chip
                                                label='Feil'
                                                icon={<Icon>build</Icon>}
                                                style={{
                                                    width: '100%',
                                                    background: '#ffcc66',
                                                }}
                                            />
                                        );
                                        break;
                                }
                                return (
                                    <TableRow hover key={change.id}>
                                        <TableCell>
                                            {moment(change.ended).format(
                                                'DD.MM-YY'
                                            )}
                                        </TableCell>
                                        <TableCell>{moduleChip}</TableCell>
                                        <TableCell>{typeChip}</TableCell>
                                        <TableCell>
                                            <Box
                                                position='relative'
                                                display='inline-flex'
                                            >
                                                <CircularProgress
                                                    variant='static'
                                                    value={change.progress}
                                                />
                                                <Box
                                                    top={0}
                                                    left={0}
                                                    bottom={0}
                                                    right={0}
                                                    position='absolute'
                                                    display='flex'
                                                    alignItems='center'
                                                    justifyContent='center'
                                                >
                                                    <Typography
                                                        variant='caption'
                                                        component='div'
                                                        color='textSecondary'
                                                    >{`${Math.round(
                                                        change.progress
                                                    )}%`}</Typography>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{change.endnote}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default ChangeDone;
