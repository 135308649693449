import React, { useState, useEffect, useCallback, useContext } from 'react';
import DashBoard from '../../components/admincident/DashBoard';
import Backlog from '../../components/admincident/Backlog';
import IncidentMenu from '../../components/admincident/IncidentMenu';
import Doing from '../../components/admincident/Doing';
import Done from '../../components/admincident/Done';
import Axios from 'axios';
import SingleIncident from '../../components/admincident/SingleIncident';
import NewLogEntryDialog from '../../components/NewLogEntryDialog';
import DialogEnd from '../../components/admincident/DialogEnd';
import DialogPri from '../../components/admincident/DialogPri';
import DialogRes from '../../components/admincident/DialogRes';
import { AuthContext } from '../../helpers/context/auth-context';
import Loading from '../../components/common/Loading';
import CustomAlert from '../../components/common/CustomAlert';

const AdmIncident = () => {
    const { userData } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState();
    const [priDialog, setPriDialog] = useState(false);
    const [resDialog, setResDialog] = useState(false);
    const [logDialog, setLogDialog] = useState(false);
    const [endDialog, setEndDialog] = useState(false);

    const [menuValue, setMenuValue] = useState(0);

    const [selectedIncident, setSelectedIncident] = useState();
    const [incidentList, setIncidentList] = useState([]);
    const [incidentLog, setIncidentLog] = useState([]);
    const [adminList, setAdminList] = useState([]);

    const [responsible, setResponsible] = useState(null);
    const [priority, setPriority] = useState('');
    const [logEntry, setLogEntry] = useState('');
    const fetchOperationsGroup = useCallback(() => {
        setLoading(true);
        Axios.get('/v1/oprgroup')
            .then((res) => {
                setAdminList(res.data);
                setLoading(false);
            })
            .catch((err) => {
                setAlert({
                    title: 'Feil',
                    type: 'error',
                    messages: [
                        'Noe gikk galt under henting av data fra serveren...',
                    ],
                });
                setLoading(false);
            });
    }, []);
    const fetchIncidentLog = useCallback(() => {
        if (selectedIncident) {
            setLoading(true);
            Axios.get('/v1/incidentlog/' + selectedIncident.id)
                .then((res) => {
                    if (res.status !== 204) {
                        setIncidentLog(res.data);
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    setAlert({
                        title: 'Feil',
                        type: 'error',
                        messages: [
                            'Noe gikk galt under henting av data fra serveren...',
                        ],
                    });
                    setLoading(false);
                });
        }
    }, [selectedIncident]);
    const fetchIncidentList = useCallback(() => {
        setLoading(true);
        Axios.get('/v1/incident')
            .then((res) => {
                setIncidentList(
                    res.data.filter((entry) => entry.ignoreMe === 0)
                );
                setLoading(false);
            })
            .catch((err) => {
                setAlert({
                    title: 'Feil',
                    type: 'error',
                    messages: [
                        'Noe gikk galt under henting av data fra serveren...',
                    ],
                });
                setLoading(false);
            });
    }, []);
    const fetchSingleIncident = () => {
        setLoading(true);
        if (selectedIncident) {
            Axios.get('/v1/incident/' + selectedIncident.id)
                .then((res) => {
                    setSelectedIncident(res.data[0]);
                    setLoading(false);
                    setPriority(res.data[0].priority);
                    if (res.data[0].handler_id) {
                        if (res.data[0].handler_id === userData.id) {
                            setResponsible({
                                id: userData.id,
                                fullName: userData.fullName,
                            });
                        }
                    }
                })
                .catch((err) => {
                    setAlert({
                        title: 'Feil',
                        type: 'error',
                        messages: [
                            'Noe gikk galt under henting av data fra serveren...',
                        ],
                    });
                    setLoading(false);
                });
        }
    };
    const handleSelect = (evt, index) => {
        setIncidentLog([]);
        const tempIncident = incidentList.filter((incident) => {
            return incident.id === index;
        });
        setSelectedIncident(tempIncident[0]);
        setPriority(tempIncident[0].priority || '');
        if (tempIncident[0].handler_id) {
            if (tempIncident[0].handler_id === userData.id) {
                setResponsible({
                    id: userData.id,
                    fullName: userData.fullName,
                });
            }
        }
    };
    const handleAddLog = () => {
        if (logEntry !== '') {
            const payload = {
                id: selectedIncident.id,
                remark: logEntry,
            };
            saveLogEntry(payload);
        }
    };
    const handleCloseIncident = () => {
        console.log('Run close');
        if (logEntry !== '') {
            console.log('LogEntry OK');
            const logPayload = {
                id: selectedIncident.id,
                remark: 'Konklusjon: ' + logEntry,
            };
            const incPayload = {
                end: Date.now(),
            };
            console.log('run save');
            saveLogEntry(logPayload);
            saveIncident(incPayload);
        }
    };
    const handleSetResponsible = () => {
        if (responsible) {
            const payload = {
                handlerId: responsible.id,
            };
            saveIncident(payload);
        } else {
            console.log('Ansvarlig for hendelse kan ikke være blank');
        }
    };
    const handleSetPriority = () => {
        if (priority) {
            const payload = {
                priority: priority,
            };
            saveIncident(payload);
        }
    };
    const saveIncident = (payload) => {
        if (selectedIncident.id) {
            Axios.patch('/v1/incident/' + selectedIncident.id, payload)
                .then((res) => {
                    setPriDialog(false);
                    setResDialog(false);
                    setEndDialog(false);
                    fetchIncidentList();
                    fetchSingleIncident();
                    setAlert({
                        title: 'Lagret',
                        type: 'success',
                        messages: ['Hendelse oppdatert...'],
                    });
                })
                .catch((err) => {
                    setAlert({
                        title: 'Feil',
                        type: 'error',
                        messages: [
                            'Noe gikk galt under lagring av hendelse...',
                        ],
                    });
                });
        }
    };
    const saveLogEntry = (payload) => {
        Axios.post('/v1/incidentlog', payload)
            .then((res) => {
                fetchIncidentLog();
                setLogDialog(false);
                setLogEntry('');
                setAlert({
                    title: 'Lagret',
                    type: 'success',
                    messages: ['Ny loggføring lagret...'],
                });
            })
            .catch((err) => {
                setAlert({
                    title: 'Feil',
                    type: 'error',
                    messages: ['Noe gikk galt under lagring av loggføring...'],
                });
            });
    };

    useEffect(() => {
        fetchIncidentList();
    }, [fetchIncidentList]);

    useEffect(() => {
        fetchIncidentLog();
    }, [fetchIncidentLog]);
    useEffect(() => {
        fetchOperationsGroup();
    }, [fetchOperationsGroup]);
    let subPage = <DashBoard />;
    switch (menuValue) {
        case 1:
            subPage = (
                <Backlog
                    incidentList={incidentList}
                    handleSelect={handleSelect}
                />
            );
            break;
        case 2:
            subPage = (
                <Doing
                    incidentList={incidentList}
                    handleSelect={handleSelect}
                />
            );
            break;
        case 3:
            subPage = (
                <Done incidentList={incidentList} handleSelect={handleSelect} />
            );
            break;
        default:
            subPage = (
                <DashBoard
                    userData={userData}
                    incidentList={incidentList}
                    handleSelect={handleSelect}
                />
            );
            break;
    }
    if (!userData || loading) {
        return <Loading />;
    } else {
        return (
            <React.Fragment>
                <CustomAlert alert={alert} setAlert={setAlert} />
                <NewLogEntryDialog
                    logDialogOpen={logDialog}
                    setLogDialogOpen={setLogDialog}
                    logEntryInput={logEntry}
                    setLogEntryInput={setLogEntry}
                    handleAddLog={handleAddLog}
                />
                <DialogEnd
                    endDialog={endDialog}
                    setEndDialog={setEndDialog}
                    logEntry={logEntry}
                    setLogEntry={setLogEntry}
                    handleCloseIncident={handleCloseIncident}
                />
                <DialogPri
                    priDialog={priDialog}
                    setPriDialog={setPriDialog}
                    selectedIncident={selectedIncident}
                    priority={priority}
                    setPriority={setPriority}
                    handleSetPriority={handleSetPriority}
                />
                <DialogRes
                    resDialog={resDialog}
                    setResDialog={setResDialog}
                    adminList={adminList}
                    responsible={responsible}
                    setResponsible={setResponsible}
                    handleSetResponsible={handleSetResponsible}
                />
                <IncidentMenu
                    menuValue={menuValue}
                    setMenuValue={setMenuValue}
                    incidentList={incidentList}
                    setSelectedIncident={setSelectedIncident}
                    userData={userData}
                />
                {selectedIncident ? (
                    <SingleIncident
                        selectedIncident={selectedIncident}
                        setSelectedIncident={setSelectedIncident}
                        incidentLog={incidentLog}
                        setIncidentLog={setIncidentLog}
                        setPriDialog={setPriDialog}
                        setResDialog={setResDialog}
                        setEndDialog={setEndDialog}
                        setLogDialog={setLogDialog}
                        setLogEntry={setLogEntry}
                    />
                ) : (
                    subPage
                )}
            </React.Fragment>
        );
    }
};

export default AdmIncident;
