import React, { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios';
import LoginCard from '../../components/LoginCard';
import { AuthContext } from '../../helpers/context/auth-context';
import ErrorAlert from '../../components/common/ErrorAlert';

const Login = () => {
    const { setToken } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState();
    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: '/' } };
    const handleLogin = (evt) => {
        evt.preventDefault();
        if (username.length > 3 && password.length > 3) {
            Axios.post('v1/auth', {
                username: username,
                password: password,
            })
                .then((response) => {
                    localStorage.setItem('token', response.data.token);
                    setUsername('');
                    setPassword('');
                    setToken(response.data.token);
                    history.replace(from);
                })
                .catch((error) => {
                    setError({
                        type: 'error',
                        title: 'Feil under innlogging',
                        msg: ['Innlogging feilet.'],
                    });
                });
        } else {
            setError({
                type: 'error',
                title: 'Feil under innlogging',
                msg: ['Brukernavn og/eller passord er for kort'],
            });
        }
    };
    return (
        <React.Fragment>
            {error ? <ErrorAlert errorMsg={error} setError={setError} /> : null}
            <LoginCard
                username={username}
                setUsername={setUsername}
                password={password}
                setPassword={setPassword}
                handleLogin={handleLogin}
            />
        </React.Fragment>
    );
};

export default Login;
