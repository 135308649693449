import React from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const RCStats = (props) => {
    return (
        <Card style={{ margin: 10 }}>
            <CardHeader title='Statistikk RC Lillesand antall oppdrag' />
            <CardContent>
                {props.missionStatYear.length > 0 ? (
                    <ResponsiveContainer width='100%' aspect={16 / 9}>
                        <BarChart
                            data={props.missionStatYear}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis dataKey='year' />
                            <YAxis />
                            <Tooltip
                                payload={[{ msg: 'blah' }, { msg: 'neh' }]}
                            />
                            <Legend />
                            <Bar dataKey='Øvelse' stackId='a' fill='#003f5c' />
                            <Bar
                                dataKey='Patrulje'
                                stackId='a'
                                fill='#374c80'
                            />
                            <Bar
                                dataKey='SAR oppdrag'
                                stackId='a'
                                fill='#7a5195'
                            />
                            <Bar
                                dataKey='Nivå 3 - egentrening'
                                stackId='a'
                                fill='#bc5090'
                            />
                            <Bar
                                dataKey='Ambulanseoppdrag'
                                stackId='a'
                                fill='#ef5675'
                            />
                            <Bar dataKey='Slep' stackId='a' fill='#ff764a' />
                        </BarChart>
                    </ResponsiveContainer>
                ) : null}
            </CardContent>
        </Card>
    );
};

export default RCStats;
