import React from 'react';
import {
    SwipeableDrawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    ListSubheader,
    Icon,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const Menu = ({ menuOpen, toggleMenu, mySarBacklog, userData }) => {
    return (
        <SwipeableDrawer
            anchor='left'
            open={menuOpen}
            onClose={toggleMenu}
            onOpen={toggleMenu}
        >
            <List>
                <ListItem button component={Link} to='/' onClick={toggleMenu}>
                    <ListItemIcon>
                        <Icon>home</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Hjem' />
                </ListItem>
                {userData ? (
                    <React.Fragment>
                        {userData.isLogin ? (
                            <ListItem
                                button
                                component={Link}
                                to='/statistikk'
                                onClick={toggleMenu}
                            >
                                <ListItemIcon>
                                    <Icon>equalizer</Icon>
                                </ListItemIcon>
                                <ListItemText primary='Statistikk' />
                            </ListItem>
                        ) : null}
                        {userData.isLogin ? (
                            <ListItem
                                button
                                onClick={toggleMenu}
                                component={Link}
                                to='/changelog'
                            >
                                <ListItemIcon>
                                    <Icon>note</Icon>
                                </ListItemIcon>
                                <ListItemText primary='Changelog/req' />
                            </ListItem>
                        ) : null}
                        <Divider />
                        <ListSubheader>Drift</ListSubheader>
                        {userData.isLogin ? (
                            <ListItem
                                button
                                onClick={toggleMenu}
                                component={Link}
                                to='/ny-hendelse'
                            >
                                <ListItemIcon>
                                    <Icon>report</Icon>
                                </ListItemIcon>
                                <ListItemText primary='Ny Hendelse' />
                            </ListItem>
                        ) : null}
                        {userData.isLogin ? (
                            <ListItem
                                button
                                onClick={toggleMenu}
                                component={Link}
                                to='/vedlikehold'
                            >
                                <ListItemIcon>
                                    <Icon>build</Icon>
                                </ListItemIcon>
                                <ListItemText primary='Vedlikehold' />
                            </ListItem>
                        ) : null}
                        <Divider />
                        <ListSubheader>Mine sider</ListSubheader>
                        {userData.isLogin ? (
                            <ListItem
                                button
                                onClick={toggleMenu}
                                component={Link}
                                to='/mine-sider'
                            >
                                <ListItemIcon>
                                    <Icon>person</Icon>
                                </ListItemIcon>
                                <ListItemText primary='Min profil' />
                            </ListItem>
                        ) : null}
                        {userData.isLogin ? (
                            <ListItem
                                button
                                component={Link}
                                to='/mine-oppdrag'
                                onClick={toggleMenu}
                            >
                                <ListItemIcon>
                                    <Icon>listalt</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    primary='Mine oppdrag'
                                    secondary={
                                        mySarBacklog +
                                        ' oppdrag krever handling'
                                    }
                                />
                            </ListItem>
                        ) : null}
                        <Divider />
                        <ListSubheader>Administrasjon</ListSubheader>
                        {userData.isSuper ? (
                            <ListItem
                                button
                                onClick={toggleMenu}
                                component={Link}
                                to='/adm-oppdrag'
                            >
                                <ListItemIcon>
                                    <Icon>directionsboat</Icon>
                                </ListItemIcon>
                                <ListItemText primary='Oppdrag' />
                            </ListItem>
                        ) : null}
                        {userData.isOpr || userData.isAdmin ? (
                            <ListItem
                                button
                                onClick={toggleMenu}
                                component={Link}
                                to='/adm-hendelser'
                            >
                                <ListItemIcon>
                                    <Icon>report</Icon>
                                </ListItemIcon>
                                <ListItemText primary='Hendelser' />
                            </ListItem>
                        ) : null}
                        {/* 
                        <ListItem button onClick={toggleMenu}>
                            <ListItemIcon>
                                <Icon>people</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Brukere/Mannskap' />
                        </ListItem>
                        <ListItem button onClick={toggleMenu}>
                            <ListItemIcon>
                                <Icon>doneall</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Sjekklister' />
                        </ListItem> */}
                    </React.Fragment>
                ) : null}
            </List>
        </SwipeableDrawer>
    );
};

export default Menu;
