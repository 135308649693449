import React, { useContext } from 'react';
import { Card, CardHeader, IconButton, CardContent } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import SingleIncInfo from './SingleIncInfo';
import SingleIncDetails from './SingleIncDetails';
import SingleIncActions from './SingleIncActions';
import SingleIncLogs from './SingleIncLog';
import { AuthContext } from '../../helpers/context/auth-context';

const SingleIncident = (props) => {
    const title = 'Hendelse: #' + props.selectedIncident.id;
    const { userData } = useContext(AuthContext);
    return (
        <Card style={{ margin: 10 }}>
            <CardHeader
                title={title}
                style={
                    props.selectedIncident.priority === 3
                        ? { background: '#D98880' }
                        : props.selectedIncident.priority === 2
                        ? { background: '#F8C471' }
                        : props.selectedIncident.priority === 1
                        ? { background: '#82E0AA' }
                        : null
                }
                action={
                    <IconButton
                        onClick={() => {
                            props.setSelectedIncident();
                            props.setIncidentLog([]);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            />
            <CardContent>
                <SingleIncInfo selectedIncident={props.selectedIncident} />
                <SingleIncDetails selectedIncident={props.selectedIncident} />
                <SingleIncActions
                    selectedIncident={props.selectedIncident}
                    userData={userData}
                    setPriDialog={props.setPriDialog}
                    setResDialog={props.setResDialog}
                    setEndDialog={props.setEndDialog}
                    setLogDialog={props.setLogDialog}
                    setLogEntry={props.setLogEntry}
                />
                <SingleIncLogs
                    selectedIncident={props.selectedIncident}
                    incidentLog={props.incidentLog}
                />
            </CardContent>
        </Card>
    );
};

export default SingleIncident;
