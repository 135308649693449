import React from 'react';
import {
    Grid,
    TextField,
    FormControlLabel,
    Switch,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Button,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const EditItem = (props) => {
    moment.updateLocale('nb', {
        invalidDate: 'Ugyldig dato',
    });
    return (
        <Accordion style={{ margin: 10 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h5'>{props.admTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant='outlined'
                            label='Tittel'
                            helperText={props.newTitleHelper}
                            error={props.newTitleHelper ? true : false}
                            value={props.newTitle}
                            onChange={(evt) => {
                                props.setNewTitle(evt.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant='outlined'
                            label='Beskrivelse'
                            multiline
                            helperText={props.newDescHelper}
                            error={props.newDescHelper ? true : false}
                            rows={5}
                            value={props.newDesc}
                            onChange={(evt) => {
                                props.setNewDesc(evt.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DatePicker
                            autoOk
                            ampm={false}
                            inputVariant='outlined'
                            label='Neste sjekk dato'
                            fullWidth
                            format='DD.MM-YYYY'
                            helperText={props.newNextCheckHelper}
                            error={props.newNextCheckHelper ? true : false}
                            value={props.newNextCheck}
                            onChange={(e) => {
                                props.setNewNextCheck(new Date(e));
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            variant='outlined'
                            label='Intervall x dager'
                            type='number'
                            value={props.newDaysBetween}
                            helperText={props.newDaysBetweenHelper}
                            error={props.newDaysBetweenHelper ? true : false}
                            onChange={(evt) => {
                                props.setNewDaysBetween(evt.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            variant='outlined'
                            label='Varsel x dager før'
                            type='number'
                            value={props.newNotice}
                            helperText={props.newNoticeHelper}
                            error={props.newNoticeHelper ? true : false}
                            onChange={(evt) => {
                                props.setNewNotice(evt.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={props.newAllowCrew}
                                    onChange={() => {
                                        props.setNewAllowCrew(
                                            !props.newAllowCrew
                                        );
                                    }}
                                />
                            }
                            label='Mannskap kan kvittere ut'
                            labelPlacement='start'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant='contained'
                            fullWidth
                            color='primary'
                            onClick={() => {
                                props.handleSaveElement();
                            }}
                        >
                            Lagre element
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant='contained'
                            fullWidth
                            color='secondary'
                            onClick={props.handleReset}
                        >
                            Nullstill alle felt
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default EditItem;
