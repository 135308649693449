import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    Grid,
    Button,
    DialogContent,
    Slider,
    Typography,
    TextField,
} from '@material-ui/core';

const ChangeChangeReq = (props) => {
    const [progress, setProgress] = useState(0);
    const [endNote, setEndNote] = useState('');
    useEffect(() => {
        if (props.selected) {
            console.log('yes');
            setProgress(props.selected.progress);
        }
    }, [props.selected]);
    return (
        <Dialog
            open={props.progressDialog}
            onClose={() => {
                props.setProgressDialog(false);
                props.setSelected(null);
            }}
            style={{ padding: 10 }}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle>Oppdatert request</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <strong>Beskrivelse:</strong>{' '}
                        {props.selected ? props.selected.description : null}
                    </Grid>
                    <Grid item xs={12}>
                        <strong>Meldt av:</strong>{' '}
                        {props.selected ? props.selected.author : null}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography id='range-slider' gutterBottom>
                            Progresjon:
                        </Typography>
                        <Slider
                            style={{ marginTop: 30 }}
                            min={0}
                            max={100}
                            value={progress}
                            onChange={(evt, value) => {
                                setProgress(value);
                            }}
                            valueLabelDisplay='on'
                        />
                    </Grid>
                    {progress === 100 ? (
                        <Grid item xs={12}>
                            <TextField
                                label='Notat/Avslutningsnotat'
                                multiline
                                rows={4}
                                value={endNote}
                                onChange={(evt) => {
                                    setEndNote(evt.target.value);
                                }}
                                variant='outlined'
                                fullWidth
                            ></TextField>
                        </Grid>
                    ) : null}
                    <Grid item xs={6}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={() => {
                                if (endNote !== '') {
                                    props.handleProgress(progress, endNote);
                                } else {
                                    props.handleProgress(progress);
                                }
                                setEndNote('');
                                props.setProgressDialog(false);
                            }}
                        >
                            Lagre
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant='contained'
                            color='secondary'
                            fullWidth
                            onClick={() => {
                                props.setProgressDialog(false);
                                props.setSelected(null);
                            }}
                        >
                            Avbryt
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ChangeChangeReq;
