import React from 'react';
import {
    Dialog,
    DialogTitle,
    Grid,
    Button,
    DialogContent,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListItemIcon,
    Typography,
} from '@material-ui/core';
import {
    LibraryBooks as LibraryBooksIcon,
    DirectionsBoat as DirectionsBoatIcon,
    AddToPhotos as AddToPhotosIcon,
    BorderColor as BorderColorIcon,
    Build as BuildIcon,
} from '@material-ui/icons';
const ChangeReqDialog = (props) => {
    return (
        <Dialog
            open={props.newReqDialog}
            onClose={() => {
                props.setNewReqDialog(false);
            }}
            style={{ padding: 10 }}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle>Meld om feil/endringsønske/ny funksjon</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl variant='outlined' fullWidth>
                            <InputLabel id='reqAppLabel'>
                                Velg applikasjon
                            </InputLabel>
                            <Select
                                id='reqApp'
                                labelId='reqAppLabel'
                                label='Velg applikasjon'
                                value={props.changeReqApp}
                                onChange={(evt) =>
                                    props.setChangeReqApp(evt.target.value)
                                }
                            >
                                <MenuItem value={2}>
                                    <ListItemIcon>
                                        <LibraryBooksIcon fontSize='small' />
                                    </ListItemIcon>
                                    <Typography variant='inherit'>
                                        Båt-APP
                                    </Typography>
                                </MenuItem>
                                <MenuItem value={1}>
                                    <ListItemIcon>
                                        <DirectionsBoatIcon fontSize='small' />
                                    </ListItemIcon>
                                    <Typography variant='inherit'>
                                        Portal
                                    </Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant='outlined' fullWidth>
                            <InputLabel id='reqTypeLabel'>
                                Velg type melding
                            </InputLabel>
                            <Select
                                id='newType'
                                labelId='reqTypeLabel'
                                label='Velg applikasjon'
                                value={props.changeReqType}
                                onChange={(evt) =>
                                    props.setChangeReqType(evt.target.value)
                                }
                            >
                                <MenuItem
                                    value={1}
                                    style={{ background: '#ffcc66' }}
                                >
                                    <ListItemIcon>
                                        <BuildIcon fontSize='small' />
                                    </ListItemIcon>
                                    <Typography variant='inherit'>
                                        Feil
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    value={3}
                                    style={{ background: '#99ccff' }}
                                >
                                    <ListItemIcon>
                                        <BorderColorIcon fontSize='small' />
                                    </ListItemIcon>
                                    <Typography variant='inherit'>
                                        Endringsønske
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    value={2}
                                    style={{ background: '#66ffcc' }}
                                >
                                    <ListItemIcon>
                                        <AddToPhotosIcon fontSize='small' />
                                    </ListItemIcon>
                                    <Typography variant='inherit'>
                                        Ønske om ny funksjon
                                    </Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant='outlined'
                            fullWidth
                            value={props.changeReqDesc}
                            onChange={(evt) => {
                                props.setChangeReqDesc(evt.target.value);
                            }}
                            label='Beskrivelse'
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={props.handleNewReq}
                        >
                            Send inn
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant='contained'
                            color='secondary'
                            fullWidth
                            onClick={() => {
                                props.setNewReqDialog(false);
                                props.setChangeReqType('');
                                props.setChangeReqApp('');
                                props.setChangeReqDesc('');
                            }}
                        >
                            Avbryt
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ChangeReqDialog;
