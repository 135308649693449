import React, { useState } from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Card,
    CardHeader,
    CardContent,
    FormGroup,
    FormControlLabel,
    Switch,
} from '@material-ui/core';
import {
    Receipt as ReceiptIcon,
    Description as DescriptionIcon,
} from '@material-ui/icons';
import moment from 'moment';

const MissionTable = ({ missionList, handleSelect, tableTitle }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [showActionOnly, setShowActionOnly] = useState(false);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <Card style={{ margin: 10 }}>
            <CardHeader title={tableTitle} />
            <CardContent>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showActionOnly}
                                onChange={() =>
                                    setShowActionOnly(!showActionOnly)
                                }
                                name='checkActionReq'
                                color='primary'
                            />
                        }
                        label='Vis kun oppdrag som krever handling'
                    />
                </FormGroup>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Handlinger:</TableCell>
                                <TableCell>ID:</TableCell>
                                <TableCell>Båtfører:</TableCell>
                                <TableCell>Start:</TableCell>
                                <TableCell>Stopp:</TableCell>
                                <TableCell>Type:</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {missionList
                                .filter((item) => {
                                    if (showActionOnly) {
                                        return (
                                            item.shouldSendInvoice === 1 ||
                                            item.shouldFileSar === 1
                                        );
                                    } else {
                                        return item;
                                    }
                                })
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((mission, index) => (
                                    <TableRow
                                        key={mission.id}
                                        onClick={(evt) =>
                                            handleSelect(evt, mission.id)
                                        }
                                    >
                                        <TableCell>
                                            <ReceiptIcon
                                                style={
                                                    mission.shouldSendInvoice ===
                                                    1
                                                        ? mission.invoiceTimestamp
                                                            ? {
                                                                  color:
                                                                      '#82E0AA',
                                                              }
                                                            : {
                                                                  color:
                                                                      '#D98880',
                                                              }
                                                        : { color: '#bdbdbd' }
                                                }
                                            />
                                            <DescriptionIcon
                                                style={
                                                    mission.shouldFileSar === 1
                                                        ? mission.sarTimestamp
                                                            ? {
                                                                  color:
                                                                      '#82E0AA',
                                                              }
                                                            : {
                                                                  color:
                                                                      '#D98880',
                                                              }
                                                        : { color: '#bdbdbd' }
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>{mission.id}</TableCell>
                                        <TableCell>
                                            {mission.crew1Name}
                                        </TableCell>
                                        <TableCell>
                                            {moment(mission.startTime).format(
                                                'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {moment(mission.stopTime).format(
                                                'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {mission.missionCatName}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component='div'
                    count={
                        missionList.filter((item) => {
                            if (showActionOnly) {
                                return (
                                    item.shouldSendInvoice === 1 ||
                                    item.shouldFileSar === 1
                                );
                            } else {
                                return item;
                            }
                        }).length
                    }
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} av ${count}`
                    }
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage='Rader pr side'
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                />
            </CardContent>
        </Card>
    );
};

export default MissionTable;
