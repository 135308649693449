import React from 'react';
import {
    CircularProgress,
    Typography,
    Card,
    CardContent,
    Grid,
    Box,
} from '@material-ui/core';

const Loading = () => {
    //return <p>Blaaablaba</p>;
    return (
        <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justify='center'
            style={{ minHeight: '50vh' }}
        >
            <Grid item xs={3}>
                <Card>
                    <CardContent>
                        <Box justifyContent='center'>
                            <CircularProgress />
                            <Typography variant='h5' component='div'>
                                Laster data...
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Loading;
