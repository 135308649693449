import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from '../pages/Dashboard';
import NoMatch from '../pages/NoMatch/NoMatch';
import Login from '../pages/Login/Login';
import Stats from '../pages/Stats/Stats';
import Profile from '../pages/Profile/Profile';
import MyMissions from '../pages/MyMissions/MyMissions';
import NewIncident from '../pages/NewIncident/NewIncident';
import { AuthContext } from '../helpers/context/auth-context';
import AdmMissions from '../pages/AdmMissions/AdmMissions';
import AdmIncident from '../pages/AdmIncident/AdmIncident';
import Changelog from '../pages/Changelog/Changelog';
import Maintenance from '../pages/Maintenance/Maintenance';

const PageLoader = () => {
    const { token } = useContext(AuthContext);

    return (
        <Switch>
            <Route path='/' exact>
                <Dashboard />
            </Route>
            <Route path='/innlogging'>
                <Login />
            </Route>
            <Route path='/statistikk'>{token ? <Stats /> : <NoMatch />}</Route>
            <Route path='/mine-sider'>
                {token ? <Profile /> : <NoMatch />}
            </Route>
            <Route path='/mine-oppdrag'>
                {token ? <MyMissions /> : <NoMatch />}
            </Route>
            <Route path='/ny-hendelse'>
                {token ? <NewIncident /> : <NoMatch />}
            </Route>
            <Route path='/adm-oppdrag'>
                {token ? <AdmMissions /> : <NoMatch />}
            </Route>
            <Route path='/adm-hendelser'>
                {token ? <AdmIncident /> : <NoMatch />}
            </Route>
            <Route path='/vedlikehold'>
                {token ? <Maintenance /> : <NoMatch />}
            </Route>
            <Route path='/changelog'>
                {token ? <Changelog /> : <NoMatch />}
            </Route>
            <Route>
                <NoMatch />
            </Route>
        </Switch>
    );
};

export default PageLoader;
