import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    TextField,
    Button,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const DialogRes = (props) => {
    return (
        <Dialog
            open={props.resDialog}
            onClose={() => {
                props.setResDialog(false);
                props.setResponsible();
            }}
            style={{ padding: 10 }}
        >
            <DialogTitle>Sett ansvarlig</DialogTitle>
            <DialogContent>
                <Grid container spacing={3} style={{ marginBottom: 5 }}>
                    <Grid item xs={12}>
                        <Autocomplete
                            id='missionCat'
                            options={props.adminList}
                            noOptionsText='Ingen treff'
                            getOptionLabel={(option) => option.fullName}
                            onChange={(e, val) => props.setResponsible(val)}
                            value={props.responsible}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Sett ansvarlig'
                                    variant='outlined'
                                    style={{ marginBottom: 20 }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            color='primary'
                            variant='contained'
                            onClick={props.handleSetResponsible}
                        >
                            Lagre
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            color='secondary'
                            variant='contained'
                            onClick={() => {
                                props.setResDialog(false);
                                props.setResponsible();
                            }}
                        >
                            Avbryt
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DialogRes;
