import React from 'react';
import {
    BottomNavigation,
    BottomNavigationAction,
    Badge,
} from '@material-ui/core';
import {
    AddToPhotos as AddToPhotosIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    Work as WorkIcon,
} from '@material-ui/icons';

const ChangelogMenu = (props) => {
    return (
        <BottomNavigation
            style={{ height: 65 }}
            value={props.menuValue}
            onChange={(evt, newMenuValue) => {
                props.setMenuValue(newMenuValue);
            }}
            showLabels
        >
            <BottomNavigationAction
                label='På vent'
                icon={
                    <Badge
                        badgeContent={
                            props.userData
                                ? props.changeList.filter(
                                      (change) => change.started === null
                                  ).length
                                : null
                        }
                        color='primary'
                    >
                        <AddToPhotosIcon />
                    </Badge>
                }
            />
            <BottomNavigationAction
                label='Behandles'
                icon={
                    <Badge
                        badgeContent={
                            props.userData
                                ? props.changeList.filter(
                                      (change) =>
                                          change.started !== null &&
                                          change.ended === null
                                  ).length
                                : null
                        }
                        color='primary'
                    >
                        <WorkIcon />
                    </Badge>
                }
            />
            <BottomNavigationAction
                label='Ferdig'
                icon={
                    <Badge
                        badgeContent={
                            props.userData
                                ? props.changeList.filter(
                                      (change) => change.ended !== null
                                  ).length
                                : null
                        }
                        color='primary'
                    >
                        <AssignmentTurnedInIcon />
                    </Badge>
                }
            />
        </BottomNavigation>
    );
};

export default ChangelogMenu;
