import React, { useContext } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    TextField,
    Grid,
    Button,
    IconButton,
} from '@material-ui/core';
import { AuthContext } from '../../helpers/context/auth-context';
import { Close as CloseIcon } from '@material-ui/icons';
import moment from 'moment';

const ViewSingle = (props) => {
    const { userData } = useContext(AuthContext);
    moment.updateLocale('nb', {
        invalidDate: 'Ugyldig dato',
    });
    return (
        <Card style={{ margin: 10 }}>
            <CardHeader
                title={props.singleItem.title}
                action={
                    <IconButton
                        onClick={() => {
                            props.handleDeselect();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            variant='outlined'
                            fullWidth
                            label='Beskrivelse'
                            multiline
                            value={props.singleItem.description}
                            disabled
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant='outlined'
                            fullWidth
                            disabled
                            value={moment(props.singleItem.lastCheck).format(
                                'L'
                            )}
                            label='Siste sjekk'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant='outlined'
                            fullWidth
                            disabled
                            value={moment(props.singleItem.nextCheck).format(
                                'L'
                            )}
                            label='Neste sjekk'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            color='primary'
                            fullWidth
                            variant='contained'
                            disabled={
                                !props.singleItem.allowCrew
                                    ? !props.singleItem.allowCrew &&
                                      (userData.isOpr ||
                                          userData.isSuper ||
                                          userData.isAdmin)
                                        ? false
                                        : true
                                    : false
                            }
                            onClick={() => {
                                props.handleCheckout();
                            }}
                        >
                            Kvitter ut punkt
                        </Button>{' '}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            color='secondary'
                            fullWidth
                            variant='contained'
                            onClick={() => {
                                props.handleDeselect();
                            }}
                        >
                            Avbryt
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ViewSingle;
