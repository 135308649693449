import React, { useContext, useEffect, useState } from 'react';
import ListTable from '../../components/maintenance/ListTable';
import ViewSingle from '../../components/maintenance/ViewSingle';
import moment from 'moment';
import { AuthContext } from '../../helpers/context/auth-context';
import EditItem from '../../components/maintenance/EditItem';
import Loading from '../../components/common/Loading';
import DeleteItem from '../../components/maintenance/DeleteItem';
import Axios from 'axios';
import CustomAlert from '../../components/common/CustomAlert';
import VerifyDialog from '../../components/maintenance/VerifyDialog';

const Maintenance = () => {
    const [admTitle, setAdmTitle] = useState('Nytt element');
    const { userData } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [maintList, setMaintList] = useState([]);
    const [singleItem, setSingleItem] = useState(null);
    const [newTitle, setNewTitle] = useState('');
    const [newDesc, setNewDesc] = useState('');
    const [newNextCheck, setNewNextCheck] = useState(null);
    const [newDaysBetween, setNewDaysBetween] = useState(0);
    const [newNotice, setNewNotice] = useState(0);
    const [newAllowCrew, setNewAllowCrew] = useState(true);
    const [newTitleHelper, setNewTitleHelper] = useState();
    const [newDescHelper, setNewDescHelper] = useState();
    const [newNextCheckHelper, setNewNextCheckHelper] = useState();
    const [newDaysBetweenHelper, setNewDaysBetweenHelper] = useState();
    const [newNoticeHelper, setNewNoticeHelper] = useState();
    const [newAllowCrewHelper, setNewAllowCrewHelper] = useState();
    useEffect(() => {
        fecthList();
    }, []);
    const handleSelect = (id) => {
        const selectedItem = maintList.filter((listitem) => listitem.id === id);
        setSingleItem(selectedItem[0]);
        setNewTitle(selectedItem[0].title);
        setNewDesc(selectedItem[0].description);
        setNewNextCheck(new Date(selectedItem[0].nextCheck));
        setNewDaysBetween(selectedItem[0].daysBetween);
        setNewNotice(selectedItem[0].notice);
        setNewAllowCrew(selectedItem[0].allowCrew);
        setAdmTitle('Rediger element');
    };
    const handleDeselect = () => {
        setSingleItem(null);
        setNewTitle('');
        setNewDesc('');
        setNewNextCheck(null);
        setNewDaysBetween(0);
        setNewNotice(0);
        setNewAllowCrew(true);
        setAdmTitle('Nytt element');
    };
    const handleReset = () => {
        if (singleItem) {
            setNewTitle(singleItem.title);
            setNewDesc(singleItem.description);
            setNewNextCheck(singleItem.nextCheck);
            setNewDaysBetween(singleItem.daysBetween);
            setNewNotice(singleItem.notice);
            setNewAllowCrew(singleItem.allowCrew);
        } else {
            setSingleItem(null);
            setNewTitle('');
            setNewDesc('');
            setNewNextCheck(null);
            setNewDaysBetween(0);
            setNewNotice(0);
            setNewAllowCrew(true);
        }
    };
    const handleCheckout = () => {
        const payload = {
            lastCheck: Date.now(),
            nextCheck: parseInt(
                moment()
                    .add(singleItem.daysBetween, 'days')
                    .endOf('day')
                    .format('x')
            ),
        };
        const message = 'Kvittering virket, ny dato oppdatert...';
        updateElement(payload, message);
    };
    const handleSaveElement = () => {
        let payload = {};
        setNewTitleHelper();
        setNewDescHelper();
        setNewNextCheckHelper();
        setNewDaysBetweenHelper();
        setNewNoticeHelper();
        setNewAllowCrewHelper();
        let hasError = false;
        if (newTitle === '') {
            setNewTitleHelper('Tittel kan ikke være tom');
            hasError = true;
        }
        if (newDesc === '') {
            setNewDescHelper('Beskrivelse kan ikke være tom');
            hasError = true;
        }
        if (!newNextCheck) {
            setNewNextCheckHelper('Du må velge en "neste dato" for sjekk');
            hasError = true;
        }
        if (newDaysBetween < 1) {
            setNewDaysBetweenHelper('Du må velge et tall over 0');
            hasError = true;
        }
        if (newNotice < 1) {
            hasError = true;
            setNewNoticeHelper('Du må velge et tall over 0');
        }
        if (!hasError) {
            payload.title = newTitle;
            payload.description = newDesc;
            payload.nextCheck = parseInt(
                moment(newNextCheck).endOf('day').format('x')
            );
            payload.daysBetween = newDaysBetween;
            payload.notice = newNotice;
            payload.allowCrew = newAllowCrew;
            if (singleItem) {
                const message = 'Vedlikeholdselement ble oppdatert......';
                updateElement(payload, message);
            } else {
                saveNewElement(payload);
            }
        }
    };
    const handleDelete = () => {
        Axios.delete(`/v1/maintenance/${singleItem.id}`)
            .then((res) => {
                setDeleteDialog(false);
                handleDeselect();
                fecthList();
                setAlert({
                    title: 'Element slettet',
                    type: 'success',
                    messages: [
                        'Vedlikeholdselementet ble slettet og er ikke lengre tilgjengelig.',
                    ],
                });
            })
            .catch((err) => {
                setAlert({
                    title: 'Feil',
                    type: 'error',
                    messages: [
                        'Noe gikk galt under sletting av vedlikeholdselementet...',
                    ],
                });
            });
    };
    const saveNewElement = (payload) => {
        Axios.post('/v1/maintenance', payload)
            .then((res) => {
                fecthList();
                handleReset();
                setAlert({
                    title: 'Element lagret',
                    type: 'success',
                    messages: [
                        'Nytt vedlikeholdselement ble lagret og er nå tilgjengelig...',
                    ],
                });
            })
            .catch((err) => {
                setAlert({
                    title: 'Feil',
                    type: 'error',
                    messages: [
                        'Noe gikk galt under lagring av vedlikeholdselement...',
                    ],
                });
            });
    };
    const updateElement = (payload, message) => {
        if (singleItem) {
            Axios.patch(`/v1/maintenance/${singleItem.id}`, payload)
                .then((res) => {
                    fetchSingle(singleItem.id);
                    fecthList();
                    setAlert({
                        title: 'Element oppdatert',
                        type: 'success',
                        messages: [message],
                    });
                })
                .catch((err) => {
                    setAlert({
                        title: 'Feil',
                        type: 'error',
                        messages: [
                            'Noe gikk galt under oppdatering av vedlikeholdselement...',
                        ],
                    });
                });
        }
    };
    const fecthList = () => {
        setLoading(true);
        Axios.get('/v1/maintenance/')
            .then((res) => {
                if (res.status === 204) {
                    setAlert({
                        title: 'Ingen data',
                        type: 'warning',
                        messages: [
                            'Det finnes ennå ingen vedlikeholdselement å vise...',
                        ],
                    });
                } else {
                    setMaintList(res.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setMaintList([]);
                setLoading(false);
                setAlert({
                    title: 'Feil',
                    type: 'error',
                    messages: [
                        'Noe gikk galt under henting av data fra serveren...',
                    ],
                });
            });
    };
    const fetchSingle = (id) => {
        setLoading(true);
        Axios.get('/v1/maintenance/' + id)
            .then((res) => {
                setSingleItem(res.data[0]);
                setLoading(false);
            })
            .catch((err) => {
                setSingleItem();
                setLoading(false);
                setAlert({
                    title: 'Feil',
                    type: 'error',
                    messages: [
                        'Noe gikk galt under henting av data fra serveren...',
                    ],
                });
            });
    };
    if (!userData || loading) {
        return <Loading />;
    } else {
        return (
            <React.Fragment>
                <CustomAlert alert={alert} setAlert={setAlert} />
                <VerifyDialog
                    open={deleteDialog}
                    setOpen={setDeleteDialog}
                    action={handleDelete}
                    title='Vil du slette element?'
                />
                {singleItem ? (
                    <ViewSingle
                        singleItem={singleItem}
                        handleDeselect={handleDeselect}
                        handleCheckout={handleCheckout}
                    />
                ) : null}
                {(singleItem &&
                    (userData.isOpr || userData.isSuper || userData.isAdmin)) ||
                userData.isOpr ||
                userData.isSuper ||
                userData.isAdmin ? (
                    <EditItem
                        admTitle={admTitle}
                        newTitle={newTitle}
                        setNewTitle={setNewTitle}
                        newDesc={newDesc}
                        setNewDesc={setNewDesc}
                        newNextCheck={newNextCheck}
                        setNewNextCheck={setNewNextCheck}
                        newDaysBetween={newDaysBetween}
                        setNewDaysBetween={setNewDaysBetween}
                        newNotice={newNotice}
                        setNewNotice={setNewNotice}
                        newAllowCrew={newAllowCrew}
                        setNewAllowCrew={setNewAllowCrew}
                        handleReset={handleReset}
                        newTitleHelper={newTitleHelper}
                        newDescHelper={newDescHelper}
                        newNextCheckHelper={newNextCheckHelper}
                        newDaysBetweenHelper={newDaysBetweenHelper}
                        newNoticeHelper={newNoticeHelper}
                        newAllowCrewHelper={newAllowCrewHelper}
                        handleSaveElement={handleSaveElement}
                    />
                ) : null}
                {singleItem &&
                (userData.isOpr || userData.isSuper || userData.isAdmin) ? (
                    <DeleteItem setVerifyDialog={setDeleteDialog} />
                ) : null}
                {!singleItem ? (
                    <ListTable
                        maintList={maintList.filter((listelm) => {
                            return moment().isBetween(
                                moment(listelm.nextCheck).subtract(
                                    listelm.notice,
                                    'days'
                                ),
                                moment(listelm.nextCheck)
                            );
                        })}
                        title='Forfaller snart'
                        handleSelect={handleSelect}
                        expanded={true}
                        background='#fdffbf'
                    />
                ) : null}
                {!singleItem ? (
                    <ListTable
                        maintList={maintList.filter((listelm) => {
                            return (
                                moment(listelm.nextCheck).isBefore(moment()) ||
                                listelm.nextCheck === null
                            );
                        })}
                        title='Forfalt'
                        handleSelect={handleSelect}
                        expanded={true}
                        background='#ffbfbf'
                    />
                ) : null}
                {!singleItem ? (
                    <ListTable
                        maintList={maintList}
                        title='Vis alle'
                        handleSelect={handleSelect}
                    />
                ) : null}
            </React.Fragment>
        );
    }
};

export default Maintenance;
