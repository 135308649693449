import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Button,
} from '@material-ui/core';

const DialogPri = (props) => {
    return (
        <Dialog
            open={props.priDialog}
            onClose={() => {
                props.setPriDialog(false);
            }}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle>Sett prioritet</DialogTitle>
            <DialogContent>
                <Grid container spacing={3} style={{ marginBottom: 5 }}>
                    <Grid item xs={12}>
                        <FormControl variant='outlined' fullWidth>
                            <InputLabel id='labelNewPriority'>
                                Sett prioritet
                            </InputLabel>
                            <Select
                                id='newPriority'
                                labelId='labelNewPriority'
                                label='Endre prioritet'
                                value={props.priority}
                                onChange={(evt) =>
                                    props.setPriority(evt.target.value)
                                }
                            >
                                <MenuItem
                                    value={3}
                                    style={{ background: '#D98880' }}
                                >
                                    Høy
                                </MenuItem>
                                <MenuItem
                                    value={2}
                                    style={{ background: '#F8C471' }}
                                >
                                    Middels
                                </MenuItem>
                                <MenuItem
                                    value={1}
                                    style={{ background: '#82E0AA' }}
                                >
                                    Lav
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            color='primary'
                            variant='contained'
                            onClick={props.handleSetPriority}
                        >
                            Lagre
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            color='secondary'
                            variant='contained'
                            onClick={() => {
                                props.setPriDialog(false);
                                props.setPriority('');
                            }}
                        >
                            Avbryt
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DialogPri;
