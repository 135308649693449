import React, { useState, useEffect, useContext } from 'react';
import { Container, CssBaseline } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/common/Header';
import MyMenu from './components/common/Menu';
import PageLoader from './components/PageLoader';
import bgIMG from './assets/bgimg.jpg';
import { AuthContext } from './helpers/context/auth-context';
import Axios from 'axios';

function App() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [mySarBacklog, setMySarBacklog] = useState(0);
    const { userData } = useContext(AuthContext);
    useEffect(() => {
        if (!menuOpen) {
            Axios.get('/v1/mysarbacklog')
                .then((res) => {
                    setMySarBacklog(res.data.mySarBacklog);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [menuOpen]);
    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };
    return (
        <React.Fragment>
            <CssBaseline />
            <Router>
                <Container
                    maxWidth='lg'
                    disableGutters
                    style={{
                        backgroundImage: `url(${bgIMG})`,
                        backgroundSize: 'cover',
                        minHeight: '100vh',
                    }}
                >
                    <Header toggleMenu={handleMenuToggle} />
                    <MyMenu
                        menuOpen={menuOpen}
                        toggleMenu={handleMenuToggle}
                        mySarBacklog={mySarBacklog}
                        userData={userData}
                    />
                    <PageLoader />
                </Container>
            </Router>
        </React.Fragment>
    );
}

export default App;
