import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    TextField,
    IconButton,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import {
    Close as CloseIcon,
    ExpandMore as ExpandMoreIcon,
    AddCircle as AddCircleIcon,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import NewLogEntryDialog from '../components/NewLogEntryDialog';

const SingleMission = ({
    userData,
    mission,
    handleCloseSingleMission,
    missionLog,
    startLoc,
    setStartLoc,
    stopLoc,
    setStopLoc,
    startEng,
    setStartEng,
    stopEng,
    setStopEng,
    distance,
    setDistance,
    startTime,
    setStartTime,
    stopTime,
    setStopTime,
    missionCat,
    category,
    setCategory,
    handleUpdateMission,
    logDialogOpen,
    setLogDialogOpen,
    logEntryInput,
    setLogEntryInput,
    handleAddLog,
    handleNewSar,
    handleNewInvoice,
    isAdmin,
}) => {
    const title = 'Oppdragsid: ' + mission.id;
    const currentCatLabel = `Oppdragstype (Nåværende:${mission.missionCatName})`;
    let captain = true;
    if (userData.id === mission.crew1Id || isAdmin === true) {
        captain = false;
    }
    return (
        <React.Fragment>
            <NewLogEntryDialog
                logDialogOpen={logDialogOpen}
                setLogDialogOpen={setLogDialogOpen}
                logEntryInput={logEntryInput}
                setLogEntryInput={setLogEntryInput}
                handleAddLog={handleAddLog}
            />

            <Card style={{ margin: 10 }}>
                <CardHeader
                    title={title}
                    action={
                        <IconButton onClick={handleCloseSingleMission}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    {!captain &&
                    (mission.shouldSendInvoice || mission.shouldFileSar) ? (
                        <Card>
                            <CardHeader title='Faktura/SAR rapport' />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        {!mission.invoiceMarkId ? (
                                            <Button
                                                fullWidth
                                                color='primary'
                                                variant='contained'
                                                onClick={handleNewInvoice}
                                                disabled={
                                                    !mission.shouldSendInvoice
                                                }
                                            >
                                                Marker sendt regning
                                            </Button>
                                        ) : (
                                            <Button
                                                fullWidth
                                                color='default'
                                                variant='contained'
                                                disabled
                                            >
                                                Regning sendt:{' '}
                                                {moment(
                                                    mission.invoiceTimestamp
                                                ).format('DD.MM-YYYY')}{' '}
                                                av{' '}
                                                {mission.invoiceMarkName ||
                                                    mission.invoiceMarkId}
                                            </Button>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {!mission.sarMarkId ? (
                                            <Button
                                                fullWidth
                                                color='primary'
                                                variant='contained'
                                                onClick={handleNewSar}
                                                disabled={
                                                    !mission.shouldFileSar
                                                }
                                            >
                                                Marker sendt SAR rapport
                                            </Button>
                                        ) : (
                                            <Button
                                                fullWidth
                                                color='default'
                                                variant='contained'
                                                disabled
                                            >
                                                SAR rapport sendt:{' '}
                                                {moment(
                                                    mission.sarTimestamp
                                                ).format('DD.MM-YYYY')}{' '}
                                                av{' '}
                                                {mission.sarMarkName ||
                                                    mission.sarMarkId}
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ) : null}
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6'>Oppdragsdata</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={mission.crew1Name}
                                        disabled
                                        variant='outlined'
                                        label='Båtfører'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={mission.crew2Name || ''}
                                        disabled
                                        variant='outlined'
                                        label='Navigatør'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={mission.crew3Name || ''}
                                        disabled
                                        variant='outlined'
                                        label='Førstehjelper'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={mission.crew4Name || ''}
                                        disabled
                                        variant='outlined'
                                        label='Mannskap'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={startLoc}
                                        onChange={(evt) => {
                                            setStartLoc(evt.target.value);
                                        }}
                                        disabled={captain}
                                        variant='outlined'
                                        label='Startlokasjon'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={stopLoc}
                                        onChange={(evt) => {
                                            setStopLoc(evt.target.value);
                                        }}
                                        disabled={captain}
                                        variant='outlined'
                                        label='Stopplokasjon'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DateTimePicker
                                        autoOk
                                        ampm={false}
                                        value={startTime}
                                        onChange={(e) => {
                                            setStartTime(new Date(e));
                                        }}
                                        disabled={captain}
                                        inputVariant='outlined'
                                        label='Starttid'
                                        fullWidth
                                        format='DD.MM-YYYY[, kl. ]HH:mm:ss'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DateTimePicker
                                        autoOk
                                        ampm={false}
                                        value={stopTime}
                                        onChange={(e) => {
                                            setStopTime(new Date(e));
                                        }}
                                        disabled={captain}
                                        inputVariant='outlined'
                                        label='Stopptid'
                                        fullWidth
                                        format='DD.MM-YYYY[, kl. ]HH:mm:ss'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={startEng}
                                        onChange={(evt) => {
                                            setStartEng(evt.target.value);
                                        }}
                                        type='number'
                                        disabled={captain}
                                        variant='outlined'
                                        label='Motortimer ved start'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={stopEng}
                                        onChange={(evt) => {
                                            setStopEng(evt.target.value);
                                        }}
                                        type='number'
                                        disabled={captain}
                                        variant='outlined'
                                        label='Motortimer ved slutt'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={distance}
                                        type='number'
                                        onChange={(evt) => {
                                            setDistance(evt.target.value);
                                        }}
                                        disabled={captain}
                                        variant='outlined'
                                        label='Avlagt avstand'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        id='missionCat'
                                        value={category}
                                        disabled={captain}
                                        options={missionCat}
                                        noOptionsText='Ingen treff'
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, val) => setCategory(val)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={currentCatLabel}
                                                variant='outlined'
                                                style={{ marginBottom: 20 }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {!captain ? (
                                    <Grid item xs={12}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            fullWidth
                                            onClick={handleUpdateMission}
                                        >
                                            Lagre endringer
                                        </Button>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6'>Oppdragslogg</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: 200 }}>
                                                Tid
                                            </TableCell>
                                            <TableCell style={{ width: 200 }}>
                                                Type
                                            </TableCell>
                                            <TableCell>Innhold</TableCell>
                                            <TableCell padding='checkbox'>
                                                <IconButton
                                                    color='primary'
                                                    onClick={() => {
                                                        setLogDialogOpen(true);
                                                    }}
                                                >
                                                    <AddCircleIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {missionLog.map((logEntry, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {moment(
                                                        logEntry.timestamp
                                                    ).format(
                                                        'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {logEntry.category}
                                                </TableCell>
                                                <TableCell>
                                                    {logEntry.remark}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default SingleMission;
