import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';

const SingleIncInfo = (props) => {
    let severity = 'Ikke satt';
    if (props.selectedIncident.priority) {
        if (props.selectedIncident.priority === 3) {
            severity = 'Høy';
        }
        if (props.selectedIncident.priority === 2) {
            severity = 'Middels';
        }
        if (props.selectedIncident.priority === 1) {
            severity = 'Lav';
        }
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
                <Typography variant='button'>
                    Melder: {props.selectedIncident.author}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='button'>
                    Behandler: {props.selectedIncident.fullName || 'Ikke satt'}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='button'>
                    Meldt:{' '}
                    {moment(props.selectedIncident.start).format(
                        'DD.MM-YYYY[, kl. ]HH:mm:ss'
                    )}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='button'>
                    Avsluttet:{' '}
                    {props.selectedIncident.end
                        ? moment(props.selectedIncident.end).format(
                              'DD.MM-YYYY[, kl. ]HH:mm:ss'
                          )
                        : 'Pågående'}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='button'>
                    Kategori: {props.selectedIncident.catName}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='button'>Prioritet: {severity}</Typography>
            </Grid>
        </Grid>
    );
};

export default SingleIncInfo;
