import React from 'react';
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Button,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const DeleteItem = (props) => {
    return (
        <Accordion style={{ margin: 10 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h5'>Slett element</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Button
                    variant='contained'
                    fullWidth
                    color='secondary'
                    onClick={() => {
                        props.setVerifyDialog(true);
                    }}
                >
                    Slett element
                </Button>
            </AccordionDetails>
        </Accordion>
    );
};

export default DeleteItem;
