import React, { useState, useEffect, useContext } from 'react';
import PersonalDataCard from '../../components/PersonalDataCard';
import Axios from 'axios';
import ErrorAlert from '../../components/common/ErrorAlert';
import { AuthContext } from '../../helpers/context/auth-context';

const Profile = () => {
    const { userData } = useContext(AuthContext);
    const { handleRefreshUserData } = useContext(AuthContext);
    const [error, setError] = useState();
    const [userid, setUserid] = useState('');
    const [username, setUsername] = useState('');
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    useEffect(() => {
        if (userData) {
            setUserid(userData.id);
            setUsername(userData.userName);
            setFullname(userData.fullName);
            setEmail(userData.email);
            setPhone(userData.phone);
        }
    }, [userData]);

    const handleSaveUserDetails = () => {
        setError();
        let validationErrors = [];
        if (username.length < 3) {
            validationErrors.push('Brukernavn kan ikke være under 3 tegn');
            setUsername(userData.userName);
        }
        if (fullname === '') {
            validationErrors.push('Navn kan ikke være tomt');
            setFullname(userData.fullName);
        }
        let regEx = new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        if (!regEx.test(email)) {
            validationErrors.push('Du må skrive inn gyldig mailadresse');
        }
        if (validationErrors.length === 0) {
            const payload = {
                username: username,
                email: email,
                phone: phone,
                fullname: fullname,
            };
            Axios.patch(`v1/user/${userid}`, payload)
                .then((res) => {
                    setError({
                        type: 'success',
                        title: 'Brukerdata oppdatert',
                        msg: ['Endring i din bruker er lagret...'],
                    });
                    handleRefreshUserData();
                })
                .catch((err) => {
                    setError({
                        type: 'error',
                        title: 'Feil i brukernavn',
                        msg: [err.response.data.msg],
                    });
                });
        } else {
            setError({
                type: 'error',
                title: 'Validering feilet',
                msg: validationErrors,
            });
        }
    };
    const handleSavePassword = () => {
        setError();
        let validationErrors = [];
        if (
            currentPassword === '' ||
            newPassword === '' ||
            repeatPassword === ''
        ) {
            validationErrors.push(
                'Nåværende, nytt eller gjentatt nytt passord kan ikke være blank...'
            );
        }
        if (newPassword !== repeatPassword) {
            validationErrors.push('De to nye passordene må være like...');
        }
        if (validationErrors.length === 0) {
            const payload = {
                username: username,
                password: currentPassword,
            };
            Axios.post('v1/auth', payload)
                .then((res) => {
                    const payload = {
                        password: newPassword,
                    };
                    Axios.patch(`v1/user/${userid}`, payload)
                        .then((res) => {
                            setCurrentPassword('');
                            setNewPassword('');
                            setRepeatPassword('');
                            setError({
                                type: 'success',
                                title: 'Passord lagret',
                                msg: [
                                    'Nytt passord er nå lagret for din bruker...',
                                ],
                            });
                        })
                        .catch((err) => {
                            setError({
                                type: 'error',
                                title: 'Feil under lagring',
                                msg: [
                                    'Noe gikk galt under lagring av nytt passord...',
                                ],
                            });
                        });
                })
                .catch((err) => {
                    setError({
                        type: 'error',
                        title: 'Feil passord',
                        msg: ['Ditt nåværende passord er feil...'],
                    });
                });
        } else {
            setError({
                type: 'error',
                title: 'Validering feilet',
                msg: validationErrors,
            });
        }
    };
    return (
        <React.Fragment>
            {error ? <ErrorAlert errorMsg={error} setError={setError} /> : null}
            <PersonalDataCard
                username={username}
                setUsername={setUsername}
                fullname={fullname}
                setFullname={setFullname}
                email={email}
                setEmail={setEmail}
                phone={phone}
                setPhone={setPhone}
                currentPassword={currentPassword}
                setCurrentPassword={setCurrentPassword}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                repeatPassword={repeatPassword}
                setRepeatPassword={setRepeatPassword}
                handleSaveUserDetails={handleSaveUserDetails}
                handleSavePassword={handleSavePassword}
            />
        </React.Fragment>
    );
};

export default Profile;
