import React from 'react';
import {
    BottomNavigation,
    BottomNavigationAction,
    Badge,
} from '@material-ui/core/';
import {
    Dashboard as DahsboardIcon,
    Event as EventIcon,
    EventAvailable as EventAvailableIcon,
    EventBusy as EventBusyIcon,
} from '@material-ui/icons';

const IncidentMenu = (props) => {
    return (
        <BottomNavigation
            value={props.menuValue}
            onChange={(event, newValue) => {
                props.setSelectedIncident();
                props.setMenuValue(newValue);
            }}
            showLabels
        >
            <BottomNavigationAction
                label='Dashboard'
                icon={
                    <Badge
                        badgeContent={
                            props.userData
                                ? props.incidentList.filter(
                                      (incident) =>
                                          incident.handler_id ===
                                              props.userData.id &&
                                          incident.end === null
                                  ).length
                                : null
                        }
                        color='primary'
                    >
                        <DahsboardIcon />
                    </Badge>
                }
                style={{ paddingTop: 15 }}
            />
            <BottomNavigationAction
                label='Innkommende'
                icon={
                    <Badge
                        badgeContent={
                            props.incidentList.filter((incident) => {
                                return incident.handler_id === null;
                            }).length
                        }
                        color='primary'
                    >
                        <EventIcon />
                    </Badge>
                }
                style={{ paddingTop: 15 }}
            />
            <BottomNavigationAction
                label='Pågående'
                icon={
                    <Badge
                        badgeContent={
                            props.incidentList.filter(
                                (incident) =>
                                    incident.handler_id !== null &&
                                    incident.end === null
                            ).length
                        }
                        color='primary'
                    >
                        <EventAvailableIcon />
                    </Badge>
                }
                style={{ paddingTop: 15 }}
            />
            <BottomNavigationAction
                label='Avsluttet'
                icon={
                    <Badge
                        badgeContent={
                            props.incidentList.filter(
                                (incident) => incident.end !== null
                            ).length
                        }
                        color='primary'
                    >
                        <EventBusyIcon />
                    </Badge>
                }
                style={{ paddingTop: 15 }}
            />
        </BottomNavigation>
    );
};

export default IncidentMenu;
