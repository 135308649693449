import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    TextField,
    Button,
} from '@material-ui/core';

const LoginCard = ({
    username,
    setUsername,
    password,
    setPassword,
    handleLogin,
}) => {
    return (
        <Card style={{ margin: 10 }}>
            <CardHeader title='Innlogging' />
            <CardContent>
                <TextField
                    style={{ marginBottom: 10 }}
                    id='username'
                    variant='outlined'
                    type='text'
                    fullWidth
                    value={username}
                    onChange={(evt) => {
                        setUsername(evt.target.value);
                    }}
                    label='Brukernavn'
                    autoComplete='username'
                />
                <TextField
                    style={{ marginBottom: 10 }}
                    type='password'
                    id='password'
                    variant='outlined'
                    fullWidth
                    value={password}
                    onChange={(evt) => {
                        setPassword(evt.target.value);
                    }}
                    label='Passord'
                    autoComplete='current-password'
                />
                <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={handleLogin}
                >
                    Logg inn
                </Button>
            </CardContent>
        </Card>
    );
};

export default LoginCard;
