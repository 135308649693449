import React from 'react';
import {
    Dialog,
    DialogTitle,
    Grid,
    Button,
    DialogContent,
    TextField,
} from '@material-ui/core';

const DialogEnd = (props) => {
    return (
        <Dialog
            open={props.endDialog}
            onClose={() => {
                props.setEndDialog(false);
            }}
            style={{ padding: 10 }}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle>Avslutt hendelse?</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant='outlined'
                            fullWidth
                            value={props.logEntry}
                            onChange={(evt) => {
                                props.setLogEntry(evt.target.value);
                            }}
                            label='Konklusjon'
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={props.handleCloseIncident}
                        >
                            Ja
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant='contained'
                            color='secondary'
                            fullWidth
                            onClick={() => {
                                props.setEndDialog(false);
                                props.setLogEntry('');
                            }}
                        >
                            Nei
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DialogEnd;
