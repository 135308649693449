import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Chip,
    CircularProgress,
    Box,
    Typography,
    Icon,
    IconButton,
    Tooltip,
    TableContainer,
} from '@material-ui/core';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import moment from 'moment';

const ChangeBacklog = (props) => {
    return (
        <Card style={{ margin: 10 }}>
            <CardHeader
                title='Innkommende:'
                action={
                    <Tooltip title='Ny henvendelse'>
                        <IconButton
                            aria-label='settings'
                            onClick={() => {
                                props.setNewReqDialog(true);
                            }}
                        >
                            <AddCircleIcon color='primary' fontSize='large' />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent>
                <Typography variant='body1'>
                    Changeloggen viser hva som jobbes med av funksjoner og ca
                    status på dem. I tillegg kan man legge inn ønske om nye
                    funksjoner, endring i funksjoner eller melde om feil.
                </Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: 100 }}>
                                    Meldt
                                </TableCell>
                                <TableCell style={{ width: 100 }}>
                                    Hvor
                                </TableCell>
                                <TableCell style={{ width: 150 }}>
                                    Type
                                </TableCell>
                                <TableCell style={{ width: 100 }}>
                                    Progresjon
                                </TableCell>
                                <TableCell>Beskrivelse</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.changeList
                                .filter((change) => change.started === null)
                                .map((change) => {
                                    let moduleChip = <Chip />;
                                    switch (change.app) {
                                        case 1:
                                            moduleChip = (
                                                <Chip
                                                    label='Portal'
                                                    icon={
                                                        <Icon>
                                                            library_books
                                                        </Icon>
                                                    }
                                                    style={{ width: '100%' }}
                                                />
                                            );
                                            break;
                                        default:
                                            moduleChip = (
                                                <Chip
                                                    label='Båt-APP'
                                                    icon={
                                                        <Icon>
                                                            directions_boat
                                                        </Icon>
                                                    }
                                                    style={{ width: '100%' }}
                                                />
                                            );
                                            break;
                                    }
                                    let typeChip = <Chip />;
                                    switch (change.type) {
                                        case 2:
                                            typeChip = (
                                                <Chip
                                                    label='Nytt'
                                                    icon={
                                                        <Icon>
                                                            add_to_photos
                                                        </Icon>
                                                    }
                                                    style={{
                                                        width: '100%',
                                                        background: '#66ffcc',
                                                    }}
                                                />
                                            );
                                            break;
                                        case 3:
                                            typeChip = (
                                                <Chip
                                                    label='Endring'
                                                    icon={
                                                        <Icon>
                                                            border_color
                                                        </Icon>
                                                    }
                                                    style={{
                                                        width: '100%',
                                                        background: '#99ccff',
                                                    }}
                                                />
                                            );
                                            break;
                                        default:
                                            typeChip = (
                                                <Chip
                                                    label='Feil'
                                                    icon={<Icon>build</Icon>}
                                                    style={{
                                                        width: '100%',
                                                        background: '#ffcc66',
                                                    }}
                                                />
                                            );
                                            break;
                                    }
                                    return (
                                        <TableRow
                                            hover
                                            key={change.id}
                                            onClick={() => {
                                                props.handleSelect(change.id);
                                                props.setStartDialog(true);
                                            }}
                                        >
                                            <TableCell>
                                                {moment(change.date).format(
                                                    'DD.MM-YY'
                                                )}
                                            </TableCell>
                                            <TableCell>{moduleChip}</TableCell>
                                            <TableCell>{typeChip}</TableCell>
                                            <TableCell>
                                                <Box
                                                    position='relative'
                                                    display='inline-flex'
                                                >
                                                    <CircularProgress
                                                        variant='static'
                                                        value={change.progress}
                                                    />
                                                    <Box
                                                        top={0}
                                                        left={0}
                                                        bottom={0}
                                                        right={0}
                                                        position='absolute'
                                                        display='flex'
                                                        alignItems='center'
                                                        justifyContent='center'
                                                    >
                                                        <Typography
                                                            variant='caption'
                                                            component='div'
                                                            color='textSecondary'
                                                        >{`${Math.round(
                                                            change.progress
                                                        )}%`}</Typography>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                {change.description}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default ChangeBacklog;
