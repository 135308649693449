import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

const ErrorAlert = ({ errorMsg, setError }) => {
    return (
        <Alert
            severity={errorMsg.type}
            style={{ margin: 10 }}
            onClose={() => {
                setError();
            }}
        >
            <AlertTitle>{errorMsg.title}</AlertTitle>
            {errorMsg.msg.map((error, index) => {
                return (
                    <React.Fragment key={index}>
                        {error}
                        <br />
                    </React.Fragment>
                );
            })}
        </Alert>
    );
};

export default ErrorAlert;
