import React, { useState, useContext, useEffect } from 'react';
import {
    MobileStepper,
    Button,
    Card,
    CardHeader,
    CardContent,
    Paper,
    TextField,
    Typography,
    Divider,
    Grid,
} from '@material-ui/core';
import ErrorAlert from '../../components/common/ErrorAlert';
import {
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';
import { AuthContext } from '../../helpers/context/auth-context';
import Axios from 'axios';
import { Autocomplete } from '@material-ui/lab';

const NewIncident = () => {
    const [error, setError] = useState();
    const [activeStep, setActiveStep] = useState(0);
    const [description, setDescription] = useState('');
    const [reason, setReason] = useState('');
    const [consequence, setConsequence] = useState('');
    const [improvement, setImprovement] = useState('');
    const [incidentCatList, setIncidentCatList] = useState([]);
    const [incidentType, setIncidentType] = useState(null);
    const { userData } = useContext(AuthContext);
    const handleResetForm = () => {
        setDescription('');
        setReason('');
        setConsequence('');
        setImprovement('');
        setActiveStep(0);
    };
    const handleSendForm = () => {
        setError();
        let errors = [];
        if (!incidentType) {
            errors.push('Du må velge en kategori for hendelsen...');
        }
        if (description.length < 3) {
            errors.push('Beskrivelse må ha mer enn 3 tegn...');
        }
        if (reason.length < 3) {
            errors.push('Årsak må ha mer enn 3 tegn...');
        }
        if (consequence.length < 3) {
            errors.push('Konsekvens må ha mer enn 3 tegn...');
        }
        if (improvement.length < 3) {
            errors.push('Forbedring må ha mer enn 3 tegn...');
        }
        if (errors.length > 0) {
            setError({
                type: 'error',
                title: 'Feil under sending av hendelse',
                msg: errors,
            });
        } else {
            const payload = {
                report: description,
                reason: reason,
                consequence: consequence,
                improvement: improvement,
                categoryId: incidentType.id,
                author: userData.fullName,
            };
            Axios.post('/v1/incident', payload).then((res) => {
                console.log(res);
                setDescription('');
                setReason('');
                setConsequence('');
                setImprovement('');
                setActiveStep(0);
                setIncidentType(null);
                setError({
                    type: 'success',
                    title: 'Ny hendelse registrert',
                    msg: [
                        `Den nye hendelsen ble lagret med ID: ${res.data.insertId}`,
                    ],
                });
                setTimeout(() => {
                    setError();
                }, 10000);
            });
            setError({
                type: 'error',
                title: 'Feil under registrering av ny hendelse.',
                msg: [
                    `Noe gikk galt så den nye hendelsen kunne ikke bl lagret...`,
                ],
            });
        }
    };
    const stepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Paper elevation={0}>
                        <Typography variant='h6'>
                            Registrer ny hendelse
                        </Typography>
                        <Typography variant='body1'>
                            Dette er hendelsesregistreringssystemet til RC
                            Lillesand. Bruksområde for dette er blandt annet
                            ulykker, nestenulykker, uforutsette hendelser,
                            tekniske hendelse +++{' '}
                        </Typography>
                        <Typography variant='body1' style={{ marginTop: 10 }}>
                            Etter at du har sendt den blir hendelsesrapporten
                            håndtert i driftsgruppa og får en ansvarlig. Den som
                            er ansvarlig for den enkelte hendelse, vil få
                            tilbakemelding fra den som behandler saken.
                        </Typography>
                        <Autocomplete
                            id='incidentCat'
                            value={incidentType}
                            options={incidentCatList}
                            noOptionsText='Ingen treff'
                            getOptionLabel={(option) => option.name}
                            onChange={(e, val) => setIncidentType(val)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={'Velg hendelseskategori'}
                                    variant='outlined'
                                    style={{ marginBottom: 10, marginTop: 10 }}
                                    fullWidth
                                />
                            )}
                        />
                        {incidentType ? (
                            <Typography
                                variant='body1'
                                style={{
                                    marginTop: 10,
                                    background: 'lightgrey',
                                    padding: 10,
                                }}
                            >
                                {incidentType.description}
                            </Typography>
                        ) : null}
                        <Typography
                            variant='subtitle1'
                            style={{ marginTop: 10 }}
                        >
                            Velg først kategori før du trykker "Neste" for å
                            fortsette...
                        </Typography>
                    </Paper>
                );
            case 1:
                return (
                    <Paper elevation={0}>
                        <Typography
                            variant='body1'
                            style={{ marginBottom: 10, marginTop: 10 }}
                        >
                            Beskriv objektivt hva som har hendt. Det skal ikke
                            nevnes navn i rapporten, men brukes funksjon.
                        </Typography>
                        <TextField
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                            variant='outlined'
                            label='Beskrivelse'
                            multiline
                            rows={8}
                            fullWidth
                        />
                    </Paper>
                );
            case 2:
                return (
                    <Paper elevation={0}>
                        <Typography
                            variant='body1'
                            style={{ marginBottom: 10, marginTop: 10 }}
                        >
                            (Antatt årsak, også bakenforliggende). Mangel på
                            vedlikehold, utstyr, opplæring osv. Bakenforliggende
                            årsak. Hva var det som bidro til at dette skjedde?
                            Manglende logistikk planlegging.
                        </Typography>
                        <TextField
                            value={reason}
                            onChange={(e) => {
                                setReason(e.target.value);
                            }}
                            variant='outlined'
                            label='Årsak'
                            multiline
                            rows={8}
                            fullWidth
                        />
                    </Paper>
                );
            case 3:
                return (
                    <Paper elevation={0}>
                        <Typography
                            variant='body1'
                            style={{ marginBottom: 10, marginTop: 10 }}
                        >
                            Hva ble konsekvens, hva gikk tapt, eller hva ble
                            skadet? Hva kunne hendt i verste fall.
                        </Typography>
                        <TextField
                            value={consequence}
                            onChange={(e) => {
                                setConsequence(e.target.value);
                            }}
                            variant='outlined'
                            label='Konsekvens'
                            multiline
                            rows={8}
                            fullWidth
                        />
                    </Paper>
                );
            case 4:
                return (
                    <Paper elevation={0}>
                        <Typography
                            variant='body1'
                            style={{ marginBottom: 10, marginTop: 10 }}
                        >
                            Forbedre/ rette (eller følge allerede nedskrevne)
                            prosedyrer. Koordinere logistikk, opplæring.
                            Forbedre utstyr. Bedre opplæring av mannskaper.
                            Informasjon fra driftsenheten.
                        </Typography>
                        <TextField
                            value={improvement}
                            onChange={(e) => {
                                setImprovement(e.target.value);
                            }}
                            variant='outlined'
                            label='Forslag til forbedring'
                            multiline
                            rows={8}
                            fullWidth
                        />
                    </Paper>
                );
            case 5:
                return (
                    <Paper elevation={0}>
                        <Typography variant='h4'>Sammendrag:</Typography>
                        <Divider />
                        <Typography variant='h6'>Kategori:</Typography>
                        <Typography variant='body1'>
                            {incidentType ? incidentType.name : null}
                        </Typography>
                        <Divider />
                        <Typography variant='h6'>Beskrivelse:</Typography>
                        <Typography component='pre' variant='body1'>
                            {description}
                        </Typography>
                        <Divider />
                        <Typography variant='h6'>Årsak:</Typography>
                        <Typography component='pre' variant='body1'>
                            {reason}
                        </Typography>
                        <Divider />
                        <Typography variant='h6'>Konsekvens:</Typography>
                        <Typography component='pre' variant='body1'>
                            {consequence}
                        </Typography>
                        <Divider />
                        <Typography variant='h6'>
                            Forslag til forbedring:
                        </Typography>
                        <Typography component='pre' variant='body1'>
                            {improvement}
                        </Typography>
                        <Divider />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant='body1'>
                                    Hendelse meldt av: {userData.fullName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    variant='contained'
                                    fullWidth
                                    color='primary'
                                    onClick={handleSendForm}
                                >
                                    Registrer hendelse
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    variant='contained'
                                    fullWidth
                                    color='secondary'
                                    onClick={handleResetForm}
                                >
                                    Avbryt
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                );
            default:
                return 'Vet ikke helt jeg';
        }
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    useEffect(() => {
        Axios.get('/v1/incidenttype')
            .then((res) => {
                setIncidentCatList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    return (
        <React.Fragment>
            {error ? <ErrorAlert errorMsg={error} setError={setError} /> : null}
            <Card style={{ margin: 10 }}>
                <CardHeader title='Ny hendelse' />
                <CardContent>
                    <MobileStepper
                        variant='dots'
                        steps={6}
                        position='static'
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size='small'
                                onClick={handleNext}
                                disabled={activeStep === 5}
                            >
                                Neste <KeyboardArrowRightIcon />
                            </Button>
                        }
                        backButton={
                            <Button
                                size='small'
                                onClick={handleBack}
                                disabled={activeStep === 0}
                            >
                                <KeyboardArrowLeftIcon />
                                Tilbake
                            </Button>
                        }
                    />
                    <div>{stepContent(activeStep)}</div>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default NewIncident;
