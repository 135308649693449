import React, { useState, useEffect } from 'react';
import RCStats from '../../components/stats/RCStats';
import Axios from 'axios';
const Stats = () => {
    const [missionStatYear, setMissionStatYear] = useState([]);
    useEffect(() => {
        Axios.get('/v1/missionstats/year')
            .then((res) => {
                prepDataYear(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const prepDataYear = (data) => {
        let resultData = [];
        let tempObj = {};
        let currentYear = null;
        data.forEach((item) => {
            if (item.year !== currentYear) {
                if (currentYear !== null) {
                    resultData.push(tempObj);
                }
                tempObj = {};
                tempObj.year = item.year;
                currentYear = item.year;
            }
            if (tempObj[item.missionCatName]) {
                tempObj[item.missionCatName] =
                    tempObj[item.missionCatName] + item.count;
            } else {
                tempObj[item.missionCatName] = item.count;
            }
        });
        resultData.push(tempObj);
        setMissionStatYear(resultData);
    };

    return (
        <React.Fragment>
            <RCStats missionStatYear={missionStatYear} />
        </React.Fragment>
    );
};

export default Stats;
