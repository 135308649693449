import React from 'react';
import {
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const SingleIncDetails = (props) => {
    return (
        <Accordion variant='outlined'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h5'>Hendelsesinformasjon</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ border: '1px solid #D5D8DC' }}>
                        <Typography variant='h6'>Beskrivelse:</Typography>
                        <Typography
                            component='pre'
                            variant='body1'
                            style={{
                                background: '#EDEDED',
                                padding: 10,
                                borderRadius: 5,
                            }}
                        >
                            {props.selectedIncident.report}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ border: '1px solid #D5D8DC' }}>
                        <Typography variant='h6'>Årsak:</Typography>
                        <Typography
                            component='pre'
                            variant='body1'
                            style={{
                                background: '#EDEDED',
                                padding: 10,
                                borderRadius: 5,
                            }}
                        >
                            {props.selectedIncident.reason}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ border: '1px solid #D5D8DC' }}>
                        <Typography variant='h6'>Konsekvens:</Typography>
                        <Typography
                            component='pre'
                            variant='body1'
                            style={{
                                background: '#EDEDED',
                                padding: 10,
                                borderRadius: 5,
                            }}
                        >
                            {props.selectedIncident.consequence}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ border: '1px solid #D5D8DC' }}>
                        <Typography variant='h6'>
                            Forslag til forbedring:
                        </Typography>
                        <Typography
                            component='pre'
                            variant='body1'
                            style={{
                                background: '#EDEDED',
                                padding: 10,
                                borderRadius: 5,
                            }}
                        >
                            {props.selectedIncident.improvement}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default SingleIncDetails;
